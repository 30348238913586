import React from "react";

const AdminHead = ({ title = "", modalAction }) => {
    return (
        <section className="flex justify-center mt-24 items-center gap-6 ">
            <p className=" font-montserrat font-semibold text-curious-blue-600 text-3xl ">
                {title}
            </p>
            <button
                className=" font-montserrat font-semibold text-curious-blue-50 bg-curious-blue-500 bg-opacity-70 hover:bg-opacity-100 transition-all rounded-lg p-3"
                onClick={() => modalAction()}
            >
                Створити
            </button>
        </section>
    );
};

export default AdminHead;
