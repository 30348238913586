import React from "react";
import { motion } from "framer-motion";

const animationVariant = {
    initial: { opacity: 0, scale: 0.1 },
    inView: { opacity: 0.6, scale: 1 },
};

const BounceBox = ({ children, delay = 0, className = "", ...rest }) => {
    return (
        <motion.div
            className={className}
            variants={animationVariant}
            initial="initial"
            whileInView="inView"
            transition={{
                duration: 0.3,
                ease: "linear",
                scale: {
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001,
                },
                delay: delay,
            }}
            {...rest}
        >
            {children}
        </motion.div>
    );
};

export default BounceBox;
