import React from "react";
import { motion } from "framer-motion";

const AnimationText = ({ text = "", className = "" }) => {
    return (
        <h1 className={className}>
            {text?.split(" ").map((el, i) => (
                <motion.span
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{
                        duration: 0.25,
                        delay: i / 10,
                    }}
                    key={i}
                >
                    {el}{" "}
                </motion.span>
            ))}
        </h1>
    );
};

export default AnimationText;
