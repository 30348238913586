import React, { useState, useEffect, useCallback } from "react";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import { scrollToTop } from "../../utils/helpers";
import { apiFaq } from "../../axios";
import Loader from "../../components/Loader/Loader";
import Collapse from "../../components/Collapse/Collapse";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import Analyses from "../../components/Analyses/Analyses";

const Faq = () => {
    const [faq, setFaq] = useState([]);

    const getData = useCallback(async (objectParams) => {
        try {
            const result = await apiFaq.getFaques(objectParams);
            setFaq(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        scrollToTop();
        getData();
    }, [getData]);

    return (
        <section>
            <PageHead text={"Допомога"} />
            <div className="w-full max-w-[1200px] m-auto p-3">
                <Analyses />
                <div className="">
                    <h2 className="font-montserrat font-semibold text-3xl text-curious-blue-600 my-10 text-center">
                        Відповіді на часті запитання
                    </h2>
                    {faq?.length !== 0 ? (
                        <Collapse
                            data={faq}
                            nameAccord="question"
                            contentAccord="answer"
                        />
                    ) : (
                        <Loader />
                    )}
                </div>
                <div className="py-10">
                    <FeedbackForm text="Не знайшли відповідь на питання? Напишіть або зателефонуйте" />
                </div>
            </div>
        </section>
    );
};

export default Faq;
