import React, { useCallback, useEffect, useState } from "react";
import { apiAnalyses } from "../../axios";
import Collapse from "../Collapse/Collapse";
import Loader from "../Loader/Loader";

const Analyses = () => {
    const [analyses, setAnalyses] = useState([]);

    const getData = useCallback(async (paramsObjs) => {
        try {
            const result = await apiAnalyses.getAnalyses(paramsObjs);
            setAnalyses(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <section>
            <div className="w-full max-w-[1200px] m-auto">
                <h2 className="font-montserrat font-semibold text-curious-blue-600 text-3xl text-center mt-10">
                    Підготовка до забору аналізів
                </h2>
                <div className=" my-10">
                    {analyses.length !== 0 ? (
                        <Collapse data={analyses} />
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Analyses;
