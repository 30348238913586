import React from "react";
import { motion } from "framer-motion";

const AnimationAccordion = ({ className = "", children }) => {
    return (
        <motion.div
            className={className}
            initial="hidden"
            whileInView="visible"
            variants={{
                hidden: {
                    x: 30,
                    opacity: 0,
                },
                visible: {
                    x: 0,
                    opacity: 1,
                    transition: {
                        delay: 0.4,
                        duration: 0.5,
                    },
                },
            }}
        >
            {children}
        </motion.div>
    );
};

export default AnimationAccordion;
