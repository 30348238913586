import emailjs from "emailjs-com";

function sendEmail(e) {
    emailjs
        .sendForm(
            "service_k94gi1n",
            "template_nmo6zbc",
            e?.target,
            "U7_RYYnh1tits0J3A"
        )
        .then(
            (result) => {
                window.location.pathname.split("/");
            },
            (error) => {
                console.log(error.text);
            }
        );
}

export default sendEmail;
