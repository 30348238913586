import { Divider } from "@mui/material";
import React from "react";

const VacancyCard = ({ vacancy }) => {
    return (
        <div className="flex justify-center flex-col gap-3 px-2 py-5 rounded-xl shadow-mt w-full max-w-[350px] text-center bg-white">
            <p className=" font-montserrat font-semibold text-curious-blue-700 text-xl">
                {vacancy?.vacancyName}
            </p>
            <Divider />
            <p className=" font-montserrat font-medium text-curious-blue-600 text-lg">
                {vacancy?.descr}
            </p>
        </div>
    );
};

export default VacancyCard;
