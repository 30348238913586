import axios from "axios";
import { PATH } from "../../data/apiPath";
import { alertForRequest, getCookie } from "../../utils/helpers";

const adminServicesCategoryPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(`${PATH}/api/servicesCategories`, fieldsObj, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Послугу додано");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminServicesCategoryUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(
                `${PATH}/api/servicesCategories/${fieldsObj?.id}`,
                fieldsObj,
                {
                    headers: {
                        token: getCookie("tk"),
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Послугу оновлено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminServicesCategoryDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/servicesCategories/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Послугу видалено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

export {
    adminServicesCategoryPost,
    adminServicesCategoryUpdate,
    adminServicesCategoryDelete,
};
