import React from "react";

const Loader = () => {
    return (
        <div className="flex flex-row gap-2 w-full m-auto justify-center p-3 h-full items-center">
            <div className="w-4 h-4 rounded-full bg-curious-blue-300 animate-bounce"></div>
            <div className="w-4 h-4 rounded-full bg-curious-blue-300 animate-bounce [animation-delay:-.3s]"></div>
            <div className="w-4 h-4 rounded-full bg-curious-blue-300 animate-bounce [animation-delay:-.5s]"></div>
        </div>
    );
};

export default Loader;
