import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import Modal from "@mui/material/Modal";
import { Input } from "../../../FormElement";
import { Divider } from "@mui/material";
import SelectMulti from "../../../FormElement/components/SelectMulti";
import { apiDevisions } from "../../../../axios";

const CreateUpdateSpecialties = ({
    visible,
    onCancel,
    obj = null,
    handleSuccess,
}) => {
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
    });

    const [devisions, setDevisions] = useState([]);

    const onSubmit = async (data) => {
        handleSuccess({
            ...data,
            devision: data?.devision?.map((itm) => itm?.value),
            namesDevisions: data?.devision?.map((itm) => itm?.label),
        });
        onCancel();
    };

    const getDevisions = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getDevisions({});
    }, [getDevisions]);

    useEffect(() => {
        reset(obj);
        return () => {
            reset({});
        };
    }, [reset, obj]);

    const optionsDevisions = useMemo(
        () =>
            devisions?.map((devision) => ({
                label: devision?.nameDevision,
                value: devision?._id,
            })),
        [devisions]
    );

    return (
        <Modal
            open={visible}
            onClose={onCancel}
            className="flex justify-center items-center "
        >
            <div className=" bg-white p-5 rounded-lg">
                <p className=" font-montserrat text-curious-blue-500 text-xl text-center mb-3">
                    {obj?.id
                        ? "Оновлення спеціальності"
                        : "Додавання спеціальності"}
                </p>
                <Divider />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="p-3 w-[400px] flex flex-col gap-4"
                >
                    <Input
                        name={"specialty"}
                        control={control}
                        placeholder="Спеціальність"
                    />
                    <SelectMulti
                        name={"devision"}
                        options={optionsDevisions}
                        control={control}
                        placeholder={"Оберіть МНП"}
                    />

                    <div className=" flex justify-around items-center gap-3">
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-curious-blue-500 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                        >
                            {obj?.id ? "Оновити" : "Зберегти"}
                        </button>
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-red-400 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                            onClick={onCancel}
                        >
                            Відміна
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CreateUpdateSpecialties;
