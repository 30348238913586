import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import Modal from "@mui/material/Modal";
import { Input } from "../../../FormElement";
import InputFile from "../../../FormElement/components/InputFile";
import { uploadImage } from "../../../FormElement/Utils";
import { Divider } from "@mui/material";
import { TiDocumentDelete } from "react-icons/ti";

const CreateUpdatePartners = ({
    visible,
    onCancel,
    obj = null,
    handleSuccess,
}) => {
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
    });

    const [image, setImage] = useState("");

    const onSubmit = async (data) => {
        const resultNewUrl = await uploadImage(image);
        handleSuccess({ ...data, image: resultNewUrl?.data?.display_url });
        onCancel();
    };
    useEffect(() => {
        reset(obj);
        return () => {
            reset({});
        };
    }, [reset, obj]);

    return (
        <Modal
            open={visible}
            onClose={onCancel}
            className="flex justify-center items-center "
        >
            <div className=" bg-white p-5 rounded-lg">
                <p className=" font-montserrat text-curious-blue-500 text-xl text-center mb-3">
                    {obj?.id ? "Оновлення партнера" : "Додавання партера"}
                </p>
                <Divider />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="  p-3 w-[400px] flex flex-col gap-4"
                >
                    <Input
                        name={"partner"}
                        control={control}
                        placeholder="Назва партнера"
                    />
                    <Input
                        name={"link"}
                        control={control}
                        placeholder="Посилання"
                    />
                    <div className="flex justify-around items-center gap-2">
                        <InputFile
                            name={"image"}
                            nameImage={image?.name}
                            control={control}
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                        {image?.length !== 0 ? (
                            <div className="">
                                <button
                                    className="border-[1px] rounded-xl px-7 py-[11px] font-light font-montserrat text-lg text-curious-blue-50 bg-red-400 outline-curious-blue-500 transition-all"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setImage("");
                                    }}
                                >
                                    <TiDocumentDelete />
                                </button>
                            </div>
                        ) : (
                            false
                        )}
                    </div>
                    <div className=" flex justify-around items-center gap-3">
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-curious-blue-500 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                        >
                            {obj?.id ? "Оновити" : "Зберегти"}
                        </button>
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-red-400 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                            onClick={onCancel}
                        >
                            Відміна
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CreateUpdatePartners;
