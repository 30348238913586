import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { apiServicesCategory } from "../../../axios";
import ServiceCard from "../../../components/ServiceCard/ServiceCard";

const AllServicesCategories = () => {
    const [servCategory, setServCategory] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiServicesCategory.getServicesCategories(
                paramsObj
            );
            setServCategory(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({
            limit: 30,
        });
    }, [getData]);
    return (
        <section className="flex flex-wrap gap-6 md:gap-2 items-center justify-center">
            {servCategory?.length !== 0 ? (
                servCategory?.map((itm) => {
                    return <ServiceCard service={itm} key={itm?._id} />;
                })
            ) : (
                <Loader />
            )}
        </section>
    );
};

export default AllServicesCategories;
