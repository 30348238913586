import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminHead from "../../../containers/Universal/AdminHead/AdminHead";
import { apiDevisions } from "../../../axios";
import Table from "../../../components/TabelData/TableData";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import FilterFields from "../../../components/FilterFields/FilterFields";
import { alertForConfirmRequest } from "../../../utils/helpers";
import { useModal } from "../../../components/Modals";
import {
    adminDevisionPost,
    adminDevisionUpdate,
    adminDevisionDelete,
} from "../../../axios/admin/manageDevisions";

const AdminDevisions = () => {
    const dispatchModal = useModal();
    const [devisions, setDevisions] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            await adminDevisionPost({
                ...fieldsObj,
                schedule: {
                    ...fieldsObj?.schedule,
                    saturday:
                        fieldsObj?.schedule?.saturday?.length < 11
                            ? "Вихідний"
                            : fieldsObj?.schedule?.saturday,
                    sunday:
                        fieldsObj?.schedule?.sunday?.length < 11
                            ? "Вихідний"
                            : fieldsObj?.schedule?.sunday,
                },
            });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminDevisionDelete({
                id: id,
            });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            await adminDevisionUpdate({
                ...fieldsObj,
                schedule: {
                    ...fieldsObj?.schedule,
                    saturday:
                        fieldsObj?.schedule?.saturday?.length < 11
                            ? "Вихідний"
                            : fieldsObj?.schedule?.saturday,
                    sunday:
                        fieldsObj?.schedule?.sunday?.length < 11
                            ? "Вихідний"
                            : fieldsObj?.schedule?.sunday,
                },
            });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal("createUpdateDevision", {
                obj: obj,
                handleSuccess: (result) => {
                    obj?.id ? updateHandler(result) : createHandler(result);
                },
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            page: page,
            limit: limit,
            search: search,
        });
    }, [getData, page, search, limit]);

    const columns = useMemo(
        () => [
            {
                key: "nameDevision",
                children: "Назва аналізу",
            },
            {
                key: "lt",
                children: "Широта",
            },
            {
                key: "ln",
                children: "Довгота",
            },
            {
                key: "adress",
                style: {
                    width: 200,
                },
                children: "Адреса",
            },
            {
                key: "weekdays",
                children: "Будні дні",
            },
            {
                key: "saturday",
                children: "Субота",
            },
            {
                key: "sunday",
                children: "Неділя",
            },
            {
                key: "mobile",
                children: "Мобільний",
            },
            {
                key: "email",
                children: "Email",
            },
            {
                key: "facebook",
                children: "Facebook",
            },
            {
                key: "viber",
                children: "Viber",
            },
            {
                key: "instagram",
                children: "Instagram",
            },
            {
                key: "telegram",
                children: "Telegram",
            },
            {
                key: "clients",
                children: "Клієнтів",
            },
            {
                key: "stage",
                children: "Досвід",
            },
            {
                key: "settings",
                children: "Керування",
                style: {
                    width: 75,
                },
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            devisions.map((itm) => ({
                rowData: [
                    {
                        key: "nameDevision",
                        children: itm?.nameDevision,
                    },
                    {
                        key: "lt",
                        children: itm?.location?.lt,
                    },
                    {
                        key: "ln",
                        children: itm?.location?.ln,
                    },
                    {
                        key: "adress",
                        style: {
                            width: 150,
                        },
                        children: itm?.location?.adress,
                    },
                    {
                        key: "weekdays",
                        children: itm?.schedule?.weekdays,
                    },
                    {
                        key: "saturday",
                        children: itm?.schedule?.saturday,
                    },
                    {
                        key: "sunday",
                        children: itm?.schedule?.sunday,
                    },
                    {
                        key: "mobile",
                        children: itm?.phone?.mobile,
                    },
                    {
                        key: "email",
                        children: itm?.email,
                    },
                    {
                        key: "facebook",
                        children: itm?.social?.facebook,
                    },
                    {
                        key: "viber",
                        children: itm?.social?.viber,
                    },
                    {
                        key: "instagram",
                        children: itm?.social?.instagram,
                    },
                    {
                        key: "telegram",
                        children: itm?.social?.telegram,
                    },
                    {
                        key: "clients",
                        children: itm?.statistic?.clients,
                    },
                    {
                        key: "stage",
                        children: itm?.statistic?.stage,
                    },
                    {
                        key: "setting",
                        style: {
                            width: 75,
                        },
                        children: (
                            <div className="flex justify-center items-center gap-3">
                                <button
                                    className=" text-curious-blue-600 text-xl"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            ...itm,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className=" text-red-400 text-3xl"
                                    onClick={() =>
                                        alertForConfirmRequest(
                                            deleteHandler,
                                            itm?._id
                                        )
                                    }
                                >
                                    <MdDelete />
                                </button>
                            </div>
                        ),
                    },
                ],
            })),
        [devisions, deleteHandler, createOrUpdate]
    );
    return (
        <section className=" pl-20 pr-5 md:pl-5">
            <div className="w-full max-w-[1200px] m-auto">
                <AdminHead
                    title={"Місця надання послуг"}
                    modalAction={createOrUpdate}
                />

                <FilterFields
                    setSearch={setSearch}
                    setLimit={setLimit}
                    placeholder="Швидкий пошук"
                />
                {devisions?.length !== 0 ? (
                    <Table rows={dataRows} cols={columns} />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default AdminDevisions;
