import React, { useCallback, useEffect, useState } from "react";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import { scrollToTop } from "../../utils/helpers";
import { apiRewards } from "../../axios";
import RewardGallery from "../../containers/Rewards/RewardGallery";
import Statistic from "../../containers/MainPage/Statistic/Statistic";

const Rewards = () => {
    const [rewards, setRewards] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiRewards.getRewards(paramsObj);
            setRewards(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        scrollToTop();
        getData();
    }, [getData]);

    return (
        <section>
            <PageHead text={"Наші нагороди"} />
            <div className="w-full max-w-[1200px] m-auto p-3 my-20">
                <RewardGallery data={rewards} />
                <Statistic />
            </div>
        </section>
    );
};

export default Rewards;
