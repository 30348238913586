import React from "react";
import styles from "./SliderNews.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import NewsCard from "../NewsCard/NewsCard";

const SliderNews = ({ data, size }) => {
    const paginationStyles = {
        "--swiper-pagination-bottom": "0px",
        "--swiper-pagination-color": "#085b82",
        "--swiper-pagination-bullet-inactive-color": "#e1f4fe",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "12px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
    };
    return (
        <div className={styles.news_slider_wrapper}>
            <Swiper
                spaceBetween={20}
                style={paginationStyles}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className={styles.swiper_wrapper}
            >
                {size &&
                    data
                        .toReversed()
                        ?.slice(0, size)
                        .map((news) => (
                            <SwiperSlide key={news?._id}>
                                <NewsCard news={news} />
                            </SwiperSlide>
                        ))}
            </Swiper>
        </div>
    );
};

export default SliderNews;
