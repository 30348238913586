import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminHead from "../../../containers/Universal/AdminHead/AdminHead";
import { apiAdministration } from "../../../axios";
import Table from "../../../components/TabelData/TableData";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import FilterFields from "../../../components/FilterFields/FilterFields";
import { alertForConfirmRequest } from "../../../utils/helpers";
import { useModal } from "../../../components/Modals";
import {
    adminPersonPost,
    adminPersonUpdate,
    adminPersonDelete,
} from "../../../axios/admin/manageAdministrations";

const AdminAdministration = () => {
    const dispatchModal = useModal();
    const [administration, setAdministration] = useState([]);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiAdministration.getAdministration(paramsObj);
            setAdministration(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            console.log(fieldsObj);
            await adminPersonPost(fieldsObj);
            getData({
                limit: limit,
                search: search,
            });
        },
        [getData, search, limit]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminPersonDelete({
                id: id,
            });
            getData({
                limit: limit,
                search: search,
            });
        },
        [getData, search, limit]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            await adminPersonUpdate(fieldsObj);
            getData({
                limit: limit,
                search: search,
            });
        },
        [getData, search, limit]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal("createUpdateAdministration", {
                obj: obj,
                handleSuccess: (result) => {
                    obj?.id ? updateHandler(result) : createHandler(result);
                },
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            limit: limit,
            search: search,
        });
    }, [getData, search, limit]);

    const columns = useMemo(
        () => [
            {
                key: "pip",
                children: "ПІП",
            },
            {
                key: "position",
                children: "Посада",
            },
            {
                key: "image",
                children: "Фото",
            },
            {
                key: "phone",
                children: "Телефон",
            },
            {
                key: "settings",
                children: "Керування",
                style: {
                    width: 75,
                },
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            administration.map((itm) => ({
                rowData: [
                    {
                        key: "pip",
                        children: `${itm?.firstName} ${itm?.secondName} ${itm?.surname}`,
                    },
                    {
                        key: "position",
                        children: itm?.position,
                    },
                    {
                        key: "image",
                        children: (
                            <img
                                src={itm?.image}
                                className="w-14 h-14 rounded-xl object-cover aspect-[3/2]"
                                alt={`${itm?.firstName} ${itm?.secondName} ${itm?.surname}`}
                            />
                        ),
                    },
                    {
                        key: "phone",
                        children: itm?.phone,
                    },
                    {
                        key: "setting",
                        style: {
                            width: 75,
                        },
                        children: (
                            <div className="flex justify-center items-center gap-3">
                                <button
                                    className=" text-curious-blue-600 text-xl"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            firstName: itm?.firstName,
                                            secondName: itm?.secondName,
                                            surname: itm?.surname,
                                            position: itm?.position,
                                            phone: itm?.phone,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className=" text-red-400 text-3xl"
                                    onClick={() =>
                                        alertForConfirmRequest(
                                            deleteHandler,
                                            itm?._id
                                        )
                                    }
                                >
                                    <MdDelete />
                                </button>
                            </div>
                        ),
                    },
                ],
            })),
        [administration, deleteHandler, createOrUpdate]
    );

    return (
        <section className=" pl-20 pr-5 md:pl-5">
            <div className="w-full max-w-[1200px] m-auto">
                <AdminHead
                    title={"Адміністрація"}
                    modalAction={createOrUpdate}
                />

                <FilterFields
                    setSearch={setSearch}
                    setLimit={setLimit}
                    placeholder="Швидкий пошук"
                />
                {administration?.length !== 0 ? (
                    <Table rows={dataRows} cols={columns} />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default AdminAdministration;
