import React from "react";
import { FaArrowLeft } from "react-icons/fa";

const AdminPanel = () => {
    return (
        <section className="flex justify-center items-center min-h-[100vh] gap-3">
            <div className=" text-3xl text-curious-blue-600">
                <FaArrowLeft />
            </div>
            <p className=" font-montserrat font-semibold text-curious-blue-600 text-4xl">
                Оберіть категорію
            </p>
        </section>
    );
};

export default AdminPanel;
