import * as yup from "yup";

const REQUIRED_MESSAGE = "Поле обовʼязкове для заповнення";
const MIN_MESSAGE = "Мінімум 3 символа";

const schema = yup.object().shape({
    question: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    answer: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE),
});

export default schema;
