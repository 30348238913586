import * as yup from "yup";

const REQUIRED_MESSAGE = "Поле обовʼязкове для заповнення";
const MIN_MESSAGE = "Мінімум 3 символа";
const PHONE_MESSAGE = "Заповніть номер коректно";
const PHONE_REGULAR = /^\+38\(\d{3}\)-\d{3}-\d{2}-\d{2}$/;

const schema = yup.object().shape({
    firstName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    secondName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    surname: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE),
    position: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    phone: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .matches(PHONE_REGULAR, PHONE_MESSAGE),
});

export default schema;
