import React from "react";
import BounceBox from "../Animation/BounceBox/BounceBox";

const BlockCircles = ({ position = "left" }) => {
    return (
        <div className="overflow-hidden w-full h-full absolute">
            <BounceBox
                delay={0.6}
                className={`absolute w-[350px] h-[350px] rounded-full shadow-curious-blue-200 ${
                    position === "left" ? "left-3" : "right-3"
                } top-3 z-[0]  bg-transparent shadow-2xl`}
            ></BounceBox>
            <BounceBox
                delay={1.3}
                className={`absolute w-[350px] h-[350px] rounded-full shadow-curious-blue-300 ${
                    position === "left" ? "left-28" : "right-28"
                } top-28 z-[2] bg-transparent shadow-2xl `}
            ></BounceBox>
            <BounceBox
                delay={1}
                className={`absolute w-[350px] h-[350px] rounded-full ${
                    position === "left" ? "left-56" : "right-56"
                } top-8 z-[1] shadow-2xl shadow-curious-blue-400 bg-transparent`}
            ></BounceBox>
        </div>
    );
};

export default BlockCircles;
