import React from "react";
import { styled } from "@mui/material/styles";
import { PlusOutlined } from "@ant-design/icons";
import Accordion from "@mui/material/Accordion";
import AnimationAccordion from "../Animation/AnimationAccordion/AnimationAccordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Collapse = ({ data, size = 100 }) => {
    const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
        padding: "5px 20px 5px",
        marginTop: "10px",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "22px",
        color: "#0395d3",
        borderBottom: "2px solid #0395d3",
        background: "#e1f4fe",
        borderTop: "2px solid #0395d3",
        "& .MuiAccordionSummary-expandIconWrapper": {
            fontSize: "24px",
            color: "#0395d3",
            fontWeight: 600,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        background: "#f0faff",
        fontFamily: "Montserrat",
        fontWeight: "400",
        color: "#046e9f",
        padding: "10px 20px",
    }));
    return (
        <>
            {size &&
                data?.slice(0, size).map((obj) => {
                    return (
                        <Accordion key={obj?._id}>
                            <AccordionSummary
                                className="bg-curious-blue-600"
                                expandIcon={<PlusOutlined />}
                                aria-controls={obj?.question}
                                id={obj?._id}
                            >
                                <AnimationAccordion>
                                    {obj?.question}
                                </AnimationAccordion>
                            </AccordionSummary>
                            <AccordionDetails>{obj?.answer}</AccordionDetails>
                        </Accordion>
                    );
                })}
        </>
    );
};

export default Collapse;
