import axios from "axios";
import { PATH } from "../../data/apiPath";
import { alertForRequest, getCookie } from "../../utils/helpers";

const adminDevisionPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(`${PATH}/api/devision`, fieldsObj, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Місце надання послуг створено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminDevisionUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(`${PATH}/api/devision/${fieldsObj?.id}`, fieldsObj, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Місце надання послуг оновлено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminDevisionDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/devision/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Місце надання послуг видалено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

export { adminDevisionPost, adminDevisionUpdate, adminDevisionDelete };
