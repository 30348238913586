const mapStyle = [
    {
        stylers: [
            {
                hue: "#083249",
            },
            {
                saturation: 250,
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                lightness: 50,
            },
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
];

export default mapStyle;
