import React, { useCallback, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import navigation from "../../data/navigation";
import { Link } from "react-router-dom";
import { FaClock } from "react-icons/fa6";
import { FaSquarePhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { apiDevisions } from "../../axios";
import logo from "../../image/logo.png";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import SocialBtn from "../SocialBtn/SocialBtn";
import Loader from "../Loader/Loader";

const Header = () => {
    const [devision, setDevision] = useState(null);
    const [scrolled, setScrolled] = useState(false);

    const headerStyles = scrolled
        ? `${styles.header_wrapper_scroled_show}`
        : `${styles.header_wrapper_scroled_hide}`;

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevision(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData("65e0470fea2ed03c9db26d10");
        const handleScroll = () => setScrolled(window.scrollY > 150);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [getData]);

    return (
        <header className={styles.header_wrapper}>
            <div className={styles.header_content}>
                <div className={styles.header_clinic_info}>
                    {devision !== null ? (
                        <>
                            <div
                                className={styles.header_header_clinic_schedule}
                            >
                                <FaClock
                                    className={
                                        styles.header_clinic_schedule_clock
                                    }
                                />
                                <p
                                    className={
                                        styles.header_clinic_schedule_time
                                    }
                                >
                                    <span>ПН-ПТ:</span>{" "}
                                    {devision?.schedule?.weekdays} |{" "}
                                    <span>СБ:</span>{" "}
                                    {devision?.schedule?.saturday} |{" "}
                                    <span>НД:</span>{" "}
                                    {devision?.schedule?.sunday}
                                </p>
                            </div>
                            <div className={styles.header_clinic_contact}>
                                <div
                                    className={
                                        styles.header_clinic_contact_phone
                                    }
                                >
                                    <FaSquarePhone />
                                    <a href={`tel:${devision?.phone?.mobile}`}>
                                        {devision?.phone?.mobile}
                                    </a>
                                </div>
                                <div
                                    className={
                                        styles.header_clinic_contact_email
                                    }
                                >
                                    <MdEmail />
                                    <a
                                        href={`mailto:${devision?.phone?.email}`}
                                    >
                                        {devision?.email}
                                    </a>
                                </div>
                            </div>
                            <SocialBtn devision={devision} />
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
                <div className={styles.header_clinic_navigation}>
                    <div className={styles.header_clinic_logo}>
                        <Link to="/">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <BurgerMenu devision={devision} />
                    <nav className={styles.header_clinic_nav}>
                        <ul className={styles.header_clinic_nav_list}>
                            {navigation?.map((link, index) => (
                                <li key={index}>
                                    <Link to={link?.href}>
                                        {link?.linkName}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
            <div className={headerStyles}>
                <nav className={styles.header_wrapper_scroled}>
                    <ul className={styles.header_clinic_nav_list}>
                        {navigation?.map((link, index) => (
                            <li key={index}>
                                <Link to={link?.href}>{link?.linkName}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
