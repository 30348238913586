import * as yup from "yup";

const REQUIRED_MESSAGE = "Поле обовʼязкове для заповнення";
const MIN_MESSAGE = (number = 1) => `Мінімум ${number} символа`;
const PHONE_MESSAGE = "Заповніть номер коректно";
const PHONE_REGULAR = /^\+38\(\d{3}\)-\d{3}-\d{2}-\d{2}$/;

const schema = yup.object().shape({
    firstName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE(3)),
    lastName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE(3)),
    mobile: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .matches(PHONE_REGULAR, PHONE_MESSAGE),
});

export default schema;
