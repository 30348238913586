import * as yup from "yup";

const REQUIRED_MESSAGE = "Поле обовʼязкове для заповнення";
const MIN_MESSAGE = "Мінімум 3 символа";
const LINK_RULES =
    "Посилання на запис повинно починатись на http:// або https://";

const schema = yup.object().shape({
    partner: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE),
    image: yup.mixed().required("Необхідно завантажити фото"),
    link: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .matches(/^(https?|ftp):\/\//, LINK_RULES),
});

export default schema;
