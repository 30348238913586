import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminHead from "../../../containers/Universal/AdminHead/AdminHead";
import { apiServicesCategory } from "../../../axios";
import Table from "../../../components/TabelData/TableData";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import FilterFields from "../../../components/FilterFields/FilterFields";
import { alertForConfirmRequest, getPagination } from "../../../utils/helpers";
import { useModal } from "../../../components/Modals";
import {
    adminServicesCategoryPost,
    adminServicesCategoryUpdate,
    adminServicesCategoryDelete,
} from "../../../axios/admin/manageServicesCategory";

const AdminServiceCategory = () => {
    const dispatchModal = useModal();
    const [servicesCategories, setServicesCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");

    const getData = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiServicesCategory.getServicesCategories(
                    paramsObj
                );
                const count =
                    paramsObj?.page === 1
                        ? await apiServicesCategory.getServicesCategoriesCount(
                              paramsObj
                          )
                        : 0;
                setPagination((prev) =>
                    paramsObj?.page === 1 ? getPagination(count, limit) : prev
                );
                if (result.length === 0 && page > 1) {
                    setPage((prevPage) => prevPage - 1);
                    return;
                }
                setServicesCategories(result);
            } catch (error) {
                console.log(error);
            }
        },
        [limit, page]
    );

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            await adminServicesCategoryPost(fieldsObj);
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminServicesCategoryDelete({
                id: id,
            });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            await adminServicesCategoryUpdate(fieldsObj);
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal("createUpdateServiceCategories", {
                obj: obj,
                handleSuccess: (result) => {
                    obj?.id ? updateHandler(result) : createHandler(result);
                },
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            page: page,
            limit: limit,
            search: search,
        });
    }, [getData, page, search, limit]);

    const columns = useMemo(
        () => [
            {
                key: "categoryName",
                children: "Назва категорії",
            },
            {
                key: "descr",
                children: "Опис категорії",
            },
            {
                key: "settings",
                children: "Керування",
                style: {
                    width: 75,
                },
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            servicesCategories?.map((itm) => ({
                rowData: [
                    {
                        key: "categoryName",
                        children: itm?.categoryName,
                    },
                    {
                        key: "descr",
                        children: itm?.descr,
                    },
                    {
                        key: "setting",
                        style: {
                            width: 75,
                        },
                        children: (
                            <div className="flex justify-center items-center gap-3">
                                <button
                                    className=" text-curious-blue-600 text-xl"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            ...itm,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className=" text-red-400 text-3xl"
                                    onClick={() =>
                                        alertForConfirmRequest(
                                            deleteHandler,
                                            itm?._id
                                        )
                                    }
                                >
                                    <MdDelete />
                                </button>
                            </div>
                        ),
                    },
                ],
            })),
        [servicesCategories, deleteHandler, createOrUpdate]
    );
    return (
        <section className=" pl-20 pr-5 md:pl-5">
            <div className="w-full max-w-[1200px] m-auto">
                <AdminHead
                    title={"Категорії послуг"}
                    modalAction={createOrUpdate}
                />

                <FilterFields
                    setSearch={setSearch}
                    setLimit={setLimit}
                    placeholder="Швидкий пошук"
                />
                {servicesCategories?.length !== 0 ? (
                    <Table
                        rows={dataRows}
                        cols={columns}
                        pagination={{
                            count: pagination.length,
                            setPage: setPage,
                        }}
                    />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default AdminServiceCategory;
