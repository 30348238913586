import Swal from "sweetalert2";

export const scrollToTop = () => window.scrollTo(0, 0);

export const getCookie = (name) => {
    var matches = document.cookie.match(
        new RegExp(
            // eslint-disable-next-line
            "(?:^|; )" +
                // eslint-disable-next-line
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                "=([^;]*)"
        )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const getCurrentDate = () => {
    const date = new Date();
    return date.toLocaleDateString("uk-UA", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
};

export const deleteCookie = (name) => {
    document.cookie = name + "=; Max-Age=0";
};

export const getPagination = (count, limit) => {
    let pageCount = Math.ceil(count / limit);
    return Array.from({ length: pageCount }, (_, i) => i + 1);
};

export const clearEmptyProperty = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null && v !== undefined)
    );
};

export const successAllertForEmail = () => {
    return Swal.fire({
        title: `<p class='font-montserrat font-semibold text-3xl text-curious-blue-900'> Повідомлення надіслано, очікуйте дзвінка з рецепції</p>`,
        icon: "success",
        confirmButtonColor: "#0395d3",
        confirmButtonText: `<p class='font-montserrat font-semibold text-xl'>ОК</p>`,
        iconColor: "#0395d3",
    });
};

export const alertForRequest = (reason, code) => {
    if (code !== "ERR_BAD_REQUEST") {
        return Swal.fire({
            title: `<p class='swal_tit_txt'>${reason}</p>`,
            icon: "success",
            confirmButtonColor: "#0395d3",
            confirmButtonText: `<p class='swal_confitm_txt'>Ok</p>`,
            iconColor: "#0395d3",
        });
    } else {
        return Swal.fire({
            title: `<p class='swal_tit_txt'>${reason}</p>`,
            icon: "error",
            confirmButtonColor: "#0395d3",
            confirmButtonText: `<p class='swal_confitm_txt'>Ok</p>`,
            iconColor: "#f87171",
        });
    }
};

export const alertForConfirmRequest = (func, value) => {
    Swal.fire({
        title: "Ви дійсно бажаєте здіснити видалення?",
        text: "Дана дія являється безповоротньою!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0395d3 ",
        cancelButtonColor: "#ff0000b3",
        confirmButtonText: "Так, підтверджую",
        cancelButtonText: "Скасувати",
    }).then((result) => {
        if (result.isConfirmed) {
            func(value);
        }
    });
};
