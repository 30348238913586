import React, { useState } from "react";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import adminNavigation from "../../data/adminRoutesData";
import logo from "../../image/logo.png";

const AdminMenu = () => {
    const [active, setActive] = useState("");

    const activeStyles = "bg-curious-blue-500 text-curious-blue-50";

    return (
        <div className="w-full max-w-[300px] min-w-[300px] p-3 fixed top-0 z-[2] md:hidden bg-white shadow-mt h-screen -left-[240px] hover:left-0 transition-all overflow-x-auto">
            <img
                src={logo}
                alt=""
                className="w-full max-w-[150px] m-auto my-2"
            />
            <Divider />
            <div className=" list-none my-5 flex flex-col gap-2">
                {adminNavigation?.map((link, index) => {
                    return (
                        <Link
                            to={link?.path}
                            key={link?.text}
                            onClick={(e) => setActive(link?.text)}
                            className={`flex justify-between items-center font-montserrat font-medium ${
                                active === link?.text
                                    ? activeStyles
                                    : "text-curious-blue-600"
                            } text-base relative p-2 rounded-lg transition-all hover:bg-curious-blue-500 hover:text-curious-blue-50`}
                        >
                            {link?.text}
                            <div className="absolute right-1 text-3xl">
                                {link?.image}
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default AdminMenu;
