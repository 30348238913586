import axios from "axios";
import { PATH } from "../../data/apiPath";
import {
    alertForRequest,
    getCookie,
    getCurrentDate,
} from "../../utils/helpers";

const adminNewPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(
                `${PATH}/api/news`,
                { ...fieldsObj, date: getCurrentDate() },
                {
                    headers: {
                        token: getCookie("tk"),
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Новину створено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminNewUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(
                `${PATH}/api/news/${fieldsObj?.id}`,
                { ...fieldsObj, date: getCurrentDate() },
                {
                    headers: {
                        token: getCookie("tk"),
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Новину оновлено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminNewDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/news/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Новину видалено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

export { adminNewPost, adminNewUpdate, adminNewDelete };
