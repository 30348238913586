export const personType = [
    {
        value: "doctor",
        label: "Лікарі",
    },
    {
        value: "nurse",
        label: "Медсестри",
    },
    {
        value: "healthSpecialist",
        label: "Фахівцi у сфері здоров'я",
    },
];
