import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { apiDoctors } from "../../axios";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import { scrollToTop } from "../../utils/helpers";
import SliderDoctors from "../../components/SliderDoctors/SliderDoctors";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import FadeBox from "../../components/Animation/FadeBox/FadeBox";
import { FaUserDoctor } from "react-icons/fa6";
import Loader from "../../components/Loader/Loader";

const Doctor = () => {
    const { id } = useParams();
    const [doctor, setDoctor] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [errorRequest, setErrorRequest] = useState(null);
    const navigate = useNavigate();

    const getDatadoctor = useCallback(async (objParams) => {
        try {
            const result = await apiDoctors.getDoctor(objParams);
            setDoctor(result);
        } catch (error) {
            console.log(error);
            error && setErrorRequest(error);
        }
    }, []);

    const getDatadoctors = useCallback(async (objParams) => {
        try {
            const result = await apiDoctors.getDoctors(objParams);
            setDoctors(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        scrollToTop();
        getDatadoctor(id);
        doctor &&
            getDatadoctors({
                isDoctor: doctor?.isDoctor,
            });
        errorRequest && navigate("/error");
    }, [
        getDatadoctor,
        getDatadoctors,
        id,
        errorRequest,
        navigate,
        doctor?.isDoctor,
    ]);

    return (
        <section>
            {doctor ? (
                <>
                    <PageHead
                        text={
                            doctor &&
                            `${doctor?.firstName} ${doctor?.secondName} ${doctor?.surname}`
                        }
                    />
                    <div className="w-full max-w-[1200px] mx-auto my-14 flex justify-around md:flex-col gap-10 p-4">
                        <div className="max-w-[400px] w-full flex justify-center items-center">
                            {doctor?.image ? (
                                <img
                                    src={doctor?.image}
                                    className="object-contain rounded-xl"
                                    alt={doctor?.firstName}
                                />
                            ) : (
                                <div className="text-[350px] w-full text-curious-blue-600 md:my-0">
                                    <FaUserDoctor />
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col gap-4 w-full p-4">
                            <p className=" font-montserrat font-semibold text-curious-blue-700 text-4xl">
                                {doctor &&
                                    `${doctor?.firstName} ${doctor?.secondName} ${doctor?.surname}`}
                            </p>
                            <div className="">
                                <p className="font-montserrat font-medium text-lg text-curious-blue-600">
                                    {doctor?.specialty.length > 1
                                        ? "Спеціальності:"
                                        : "Спеціальність:"}
                                </p>
                                <ul className=" list-disc font-montserrat font-medium text-base text-curious-blue-700 italic">
                                    {doctor?.specialtiesName?.map(
                                        (spec, index) => {
                                            return (
                                                <li
                                                    className="ml-[30px]"
                                                    key={index}
                                                >
                                                    {spec}
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                            <div className="flex gap-2 items-center">
                                <p className="font-montserrat font-medium text-lg text-curious-blue-600">
                                    Освіта:
                                </p>
                                <p>{doctor?.education}</p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <p className="font-montserrat font-medium text-lg text-curious-blue-600">
                                    Категорія:
                                </p>
                                <p>{doctor?.specialtyCategory}</p>
                            </div>
                            {doctor?.treatmentLink?.length !== 0 ? (
                                <a
                                    href={doctor.treatmentLink}
                                    className="relative block bg-curious-blue-500 hover:bg-curious-blue-800 text-curious-blue-50 hover:text-curious-blue-50 transition-all px-4 py-2 rounded-xl cursor-pointer text-center max-w-[300px]"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Записатись на прийом
                                </a>
                            ) : (
                                false
                            )}
                        </div>
                    </div>
                    <div className=" bg-curious-blue-50 m-auto">
                        <SliderDoctors
                            data={doctors}
                            specialty={doctor?.specialtiesName}
                            docId={id}
                            status={true}
                        />
                    </div>
                </>
            ) : (
                <div className="my-[150px]">
                    <Loader />
                </div>
            )}
            <FadeBox className="my-10">
                <FeedbackForm text="Запишіться онлайн, або зателефонуйте" />
            </FadeBox>
        </section>
    );
};

export default Doctor;
