import { ModalStore, useModal } from "react-modal-store";

import CreateUpdatePartners from "./Components/CreateUpdatePartners";
import CreateUpdateAdministration from "./Components/CreateUpdateAdministration";
import CreateUpdateAnalyses from "./Components/CreateUpdateAnalyses";
import CreateUpdateComments from "./Components/CreateUpdateComments";
import CreateUpdateDevision from "./Components/CreateUpdateDevision";
import CreateUpdateFaq from "./Components/CreateUpdateFaq";
import CreateUpdateRewards from "./Components/CreateUpdateRewards";
import CreateUpdateServiceCategories from "./Components/CreateUpdateServiceCategories";
import CreateUpdateServices from "./Components/CreateUpdateServices";
import CreateUpdateSpecialties from "./Components/CreateUpdateSpecialties";
import CreateUpdateVacancies from "./Components/CreateUpdateVacancies";
import CreateUpdateDoctors from "./Components/CreateUpdateDoctors";
import CreateUpdateNews from "./Components/CreateUpdateNews";
import CreateUpdateDocuments from "./Components/CreateUpdateDocuments";

const modalMap = {
    createUpdatePartners: CreateUpdatePartners,
    createUpdateAdministration: CreateUpdateAdministration,
    createUpdateAnalyses: CreateUpdateAnalyses,
    createUpdateComments: CreateUpdateComments,
    createUpdateDevision: CreateUpdateDevision,
    createUpdateFaq: CreateUpdateFaq,
    createUpdateRewards: CreateUpdateRewards,
    createUpdateServiceCategories: CreateUpdateServiceCategories,
    createUpdateServices: CreateUpdateServices,
    createUpdateSpecialties: CreateUpdateSpecialties,
    createUpdateVacancies: CreateUpdateVacancies,
    createUpdateDoctors: CreateUpdateDoctors,
    createUpdateNews: CreateUpdateNews,
    createUpdateDocuments: CreateUpdateDocuments,
};

export { modalMap, ModalStore, useModal };
