import React, { useCallback, useEffect, useState } from "react";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import { apiDevisions } from "../../axios";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import FadeBox from "../../components/Animation/FadeBox/FadeBox";
import { scrollToTop } from "../../utils/helpers";
import DevisionMap from "../../components/DevisionMap/DevisionMap";
import Loader from "../../components/Loader/Loader";

const Contacts = () => {
    const [devision, setDevision] = useState(null);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevision(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);
    console.log();

    useEffect(() => {
        scrollToTop();
        getData("65e0470fea2ed03c9db26d10");
    }, [getData]);

    return (
        <section>
            <PageHead text={"Наші контакти"} />
            <div className="w-full max-w-[1200px] m-auto flex flex-col">
                <FadeBox className="flex flex-wrap  justify-around gap-7 my-8">
                    <div className="flex flex-col w-full max-w-[300px] justify-center items-center gap-3 p-3 hover:scale-110 transition-all min-h-[200px]">
                        <div className=" last:max-w-[50px] w-full text-[60px] flex justify-center text-curious-blue-600">
                            <MdEmail />
                        </div>
                        <a
                            href={`mailto:${devision?.email}`}
                            className="text-base font-montserrat font-medium text-curious-blue-800"
                        >
                            {devision?.email}
                        </a>
                    </div>
                    <div className="flex flex-col w-full max-w-[300px] justify-center items-center gap-3 p-3 hover:scale-110 transition-all min-h-[200px]">
                        <div className="  last:max-w-[50px] w-full  text-[60px] flex justify-center text-curious-blue-600">
                            <FaMapLocationDot />
                        </div>
                        <p className="text-base font-montserrat text-center font-medium text-curious-blue-800">
                            {devision?.location?.adress}
                        </p>
                    </div>
                    <div className="flex flex-col w-full max-w-[300px] justify-center items-center gap-3 p-3 hover:scale-110 transition-all min-h-[200px]">
                        <div className=" last:max-w-[50px] w-full  text-[60px] flex justify-center text-curious-blue-600">
                            <FaPhoneSquareAlt />
                        </div>
                        <a
                            href={`tel:+${devision?.phone?.mobile}`}
                            className="text-base font-montserrat font-medium text-curious-blue-800"
                        >
                            {devision?.phone?.mobile}
                        </a>
                    </div>
                </FadeBox>
                <FadeBox className=" mb-12">
                    <FeedbackForm text="Залишіть дані для звʼязку, або зателефонуйте" />
                </FadeBox>
            </div>
            {devision !== null ? (
                <DevisionMap location={devision.location} />
            ) : (
                <Loader />
            )}
        </section>
    );
};

export default Contacts;
