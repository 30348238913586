import React from "react";
import styles from "./ContactModal.module.scss";
import { Modal } from "antd";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import logo from "../../image/logo.png";

const ContactModal = ({ active, setActive }) => {
    const handleCancel = () => {
        setActive(false);
    };
    return (
        <div className="relative">
            <Modal
                open={active}
                centered
                onCancel={handleCancel}
                keyboard
                footer={false}
                mask
            >
                <img src={logo} className="w-[150px] m-auto" alt="" />
                <FeedbackForm modal={true} />
            </Modal>
        </div>
    );
};

export default ContactModal;
