import React, { useCallback, useEffect, useState } from "react";
import BlockCircles from "../../../components/BlockCircles/BlockCircles";
import { useNavigate } from "react-router-dom";
import logo from "../../../image/logo.png";
import { apiUsers } from "../../../axios";
import { getCookie } from "../../../utils/helpers";
import { BsFillPeopleFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import Link from "../../../components/Link/Link";

const Login = () => {
    const [error, setError] = useState("");
    const [confirm, setConfirm] = useState(null);
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [inputOpen, setInputOpen] = useState(false);

    const navigate = useNavigate();

    const auth = (e) => {
        e.preventDefault();
        getUser(
            {
                userInfo: {
                    username: userName,
                    password: userPassword,
                },
            },
            setError
        );
    };

    const getUser = useCallback(async (paramsObj, setError) => {
        try {
            const result = await apiUsers.getUser(paramsObj);
            document.cookie = `tk=${result?.token}; max-age=21600`;
            setConfirm(true);
        } catch (error) {
            setError(error);
        }
    }, []);

    useEffect(() => {
        if (getCookie("tk")) {
            navigate("/admin-panel");
        }
    }, [getUser, navigate, confirm]);
    return (
        <section className="flex justify-center items-center  bg-curious-blue-100 min-h-screen relative md:flex-col">
            <BlockCircles />
            <div className="flex-1 md:flex-initial justify-center items-center flex p-3 flex-col z-10 relative gap-5">
                <img
                    src={logo}
                    alt="Клініка Д.Прокопової"
                    className="w-full max-w-[350px]"
                />
                <Link path="/" text="На головну" />
            </div>
            <div className="flex-1 w-full bg-curious-blue-50 p-3 relative z-10 h-screen bg-opacity-90 flex justify-center items-center">
                <div className="">
                    <p className=" font-montserrat font-medium text-curious-blue-500 text-3xl text-center mb-10">
                        Авторизація
                    </p>
                    <form onSubmit={auth} className="flex gap-5 flex-col">
                        <div className=" flex items-center">
                            <div className=" text-2xl p-4 text-curious-blue-600 bg-curious-blue-200 rounded-s-lg">
                                <BsFillPeopleFill />
                            </div>
                            <input
                                type="text"
                                onChange={(e) => setUserName(e.target.value)}
                                className=" w-full max-w-[500px] min-w-[300px] outline-curious-blue-300 transition-all rounded-lg shadow-md border-curious-blue-300 p-4 font-montserrat font-medium placeholder:text-curious-blue-300 text-curious-blue-600"
                            />
                        </div>
                        <div className=" flex items-center relative">
                            <div className=" text-2xl p-4 text-curious-blue-600 bg-curious-blue-200 rounded-s-lg">
                                <RiLockPasswordFill />
                            </div>
                            <input
                                type={inputOpen ? "text" : "password"}
                                onChange={(e) =>
                                    setUserPassword(e.target.value)
                                }
                                className=" w-full max-w-[500px] min-w-[300px] outline-curious-blue-300 transition-all rounded-lg shadow-md border-curious-blue-300 p-4 font-montserrat font-medium placeholder:text-curious-blue-300 text-curious-blue-600"
                            />
                            <div
                                className=" cursor-pointer text-2xl p-4 text-curious-blue-600 absolute inset-y-0 right-[-5px] opacity-70"
                                onClick={(e) => setInputOpen((prev) => !prev)}
                            >
                                {inputOpen ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                        <button className="w-full max-w-[200px] bg-curious-blue-300 text-curious-blue-100 hover:bg-curious-blue-600 transition-all rounded-lg px-6 py-3 self-center mb-5">
                            Увійти
                        </button>
                    </form>
                    <p className=" text-base text-red-300 font-sans text-center">
                        {error}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Login;
