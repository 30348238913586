import axios from "axios";

export const uploadImage = async (image) => {
    if (image) {
        const formData = new FormData();
        formData.append("image", image);
        const apiKey = "214813002d1c6c70ecd7fbec5866ba55";

        try {
            const response = await axios.post(
                `https://api.imgbb.com/1/upload?key=${apiKey}`,
                formData
            );
            return response.data;
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    }
};
