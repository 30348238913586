import React, { useState, useEffect, useCallback } from "react";
import styles from "./Faq.module.scss";
import Collapse from "../../../components/Collapse/Collapse";
import { apiFaq } from "../../../axios";
import Link from "../../../components/Link/Link";
import Loader from "../../../components/Loader/Loader";

const Faq = () => {
    const [faq, setFaq] = useState([]);

    const getData = useCallback(async (objectParams) => {
        try {
            const result = await apiFaq.getFaques(objectParams);
            setFaq(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <section className={styles.faq_wrapper}>
            <div className={styles.faq_content}>
                <h3 className={styles.faq_tit}>Відповіді на питання</h3>
                {faq.length !== 0 ? (
                    <div className={styles.faq_collapse_container}>
                        <Collapse data={faq} size={4} />
                    </div>
                ) : (
                    <Loader />
                )}
                <div className={styles.faq_btn_block}>
                    <Link path="faq" text="Потрібна допомога" />
                </div>
            </div>
        </section>
    );
};

export default Faq;
