import React, { useEffect, useState } from "react";

const Counter = ({ value, delay, fastCount }) => {
    const [startValue, setStartValue] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            if (startValue < value) {
                setStartValue((prev) => prev + (fastCount ? 50 : 1));
            }
        }, delay);
        return () => clearInterval(interval);
    }, [startValue, value, delay, fastCount]);

    return (
        <div>
            <p className=" font-montserrat font-semibold text-6xl text-curious-blue-500 w-[200px]">
                {startValue}+
            </p>
        </div>
    );
};

export default Counter;
