import React from "react";
import styles from "./Link.module.scss";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ path = "", text = "", whiteMode = false }) => {
    return (
        <RouterLink
            to={`${path}`}
            className={whiteMode ? styles.link_white : styles.link_dark}
        >
            {text}
        </RouterLink>
    );
};

export default Link;
