import React, { useEffect } from 'react';
import Link from '../../components/Link/Link';
import { scrollToTop } from '../../utils/helpers';
import { useNavigate, useLocation } from 'react-router-dom';

const Error = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const redirectionOldLinks = (pathname) => {
        const splitedPath = pathname.split('/')[1];

        switch (splitedPath) {
            case 'kontakty':
                navigate('/contacts');
                break;
            case 'likari':
                navigate('/devisions');
                break;
            case 'pro-nas':
                navigate('/about-us');
                break;
            case 'category':
                navigate('/news');
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        scrollToTop();
        redirectionOldLinks(pathname);
    }, [pathname]);

    return (
        <section className="min-h-[80vh] flex justify-center items-center flex-col gap-2">
            <p className=" font-montserrat font-extrabold text-[175px] md:text-[130px] text-curious-blue-600">
                404
            </p>
            <p className=" font-montserrat font-semibold text-curious-blue-600 text-2xl text-center">
                На жаль сторінку не знайдено
            </p>
            <Link path="/" text="На головну" />
        </section>
    );
};

export default Error;
