import React from "react";
import styles from "./DoctorCard.module.scss";
import { Link } from "react-router-dom";
import { FaUserDoctor } from "react-icons/fa6";

const DoctorCard = ({ doctor }) => {
    return (
        <div className={styles.doctor_box}>
            <Link to={`/doctors/${doctor?._id}`} className={styles.doctor_img}>
                {doctor?.image ? (
                    <img src={doctor?.image} alt={doctor?.firstName} />
                ) : (
                    <FaUserDoctor />
                )}
            </Link>
            <div className={styles.doctor_info_content}>
                <div className={styles.doctor_info}>
                    <p className={styles.doctor_fio}>
                        {doctor?.firstName} <br /> {doctor?.secondName} <br />
                        {doctor?.surname}
                    </p>
                    <div className={styles.doctor_specialties}>
                        <p className={styles.doctor_specialties_tit}>
                            {doctor?.specialty?.length > 1
                                ? "Спеціальності:"
                                : "Спеціальність:"}
                        </p>
                        <ul className={styles.doctor_specialties_list}>
                            {doctor?.specialtiesName?.map((spec, index) => {
                                return <li key={index}>{spec}</li>;
                            })}
                        </ul>
                    </div>
                    <p className={styles.doctor_education}>
                        {doctor?.education}
                    </p>
                </div>
                <div className={styles.doctor_button_group}>
                    {doctor?.treatmentLink?.length !== 0 ? (
                        <a
                            href={doctor.treatmentLink}
                            className={styles.doctor_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Записатись
                        </a>
                    ) : (
                        false
                    )}
                </div>
            </div>
        </div>
    );
};

export default DoctorCard;
