import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ModalStore, modalMap } from "./components/Modals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <ModalStore modalMap={modalMap} destroyOnClose="afterClose">
            <App />
        </ModalStore>
    </BrowserRouter>
);
