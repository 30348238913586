import React from "react";
import { useController } from "react-hook-form";

const SelectForm = ({
    name,
    control,
    rules = {},
    disabled = false,
    defaultValue = "",
    ...props
}) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled,
    });

    return (
        <div className="flex flex-col gap-2">
            <hr
                noshade="true"
                className="bg-curious-blue-500 h-[2px] border-none rounded-lg"
            />
            <p className="text-center font-montserrat text-lg text-curious-blue-500">
                {props.placeholder}
            </p>
            <select
                className="font-montserrat font-medium cursor-pointer border rounded-lg p-4 text-curious-blue-800 text-lg outline-none"
                {...field}
                {...props}
            >
                {props.data?.map((elem, index) => (
                    <option
                        value={elem?.value ? elem?.value : elem}
                        key={index}
                    >
                        {elem?.label ? elem?.label : elem}
                    </option>
                ))}
            </select>
            {error?.message && (
                <p className="form_error_txt">{error?.message}</p>
            )}
        </div>
    );
};

export default SelectForm;
