import { FaPeopleGroup } from "react-icons/fa6";
import { IoPeople } from "react-icons/io5";
import { FaLaptopMedical } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { FaQuestionCircle } from "react-icons/fa";
import { FaNewspaper } from "react-icons/fa6";
import { FaMedal } from "react-icons/fa";
import { RiServiceFill } from "react-icons/ri";
import { TbMedicalCrossFilled } from "react-icons/tb";
import { FaBookMedical } from "react-icons/fa";
import { IoPeopleCircle } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import AdminPartners from "../pages/Admin-pages/AdminPartners/AdminPartners";
import AdminAdministration from "../pages/Admin-pages/AdminAdministration/AdminAdministration";
import AdminAnalyses from "../pages/Admin-pages/AdminAnalyses/AdminAnalyses";
import AdminComments from "../pages/Admin-pages/AdminComments/AdminComments";
import AdminDevisions from "../pages/Admin-pages/AdminDevisions/AdminDevisions";
import AdminFaq from "../pages/Admin-pages/AdminFaq/AdminFaq";
import AdminRewards from "../pages/Admin-pages/AdminRewards/AdminRewards";
import AdminServiceCategory from "../pages/Admin-pages/AdminServiceCategory/AdminServiceCategory";
import AdminServices from "../pages/Admin-pages/AdminServices/AdminServices";
import AdminSpecialties from "../pages/Admin-pages/AdminSpecialties/AdminSpecialties";
import AdminVacancies from "../pages/Admin-pages/AdminVacancies/AdminVacancies";
import AdminDoctors from "../pages/Admin-pages/AdminDoctors/AdminDoctors";
import AdminNews from "../pages/Admin-pages/AdminNews/AdminNews";
import AdminDocuments from "../pages/Admin-pages/AdminDocuments/AdminDocuments";

const adminNavigation = [
    {
        path: "/admin-panel/administration",
        image: <IoPeople />,
        text: "Адміністрація",
        component: <AdminAdministration />,
    },
    {
        path: "/admin-panel/analyses",
        image: <FaLaptopMedical />,
        text: "Аналізи",
        component: <AdminAnalyses />,
    },
    {
        path: "/admin-panel/comments",
        image: <FaComments />,
        text: "Коментарі",
        component: <AdminComments />,
    },
    {
        path: "/admin-panel/devisions",
        image: <FaBuilding />,
        text: "МНП",
        component: <AdminDevisions />,
    },
    {
        path: "/admin-panel/doctors",
        image: <FaUserDoctor />,
        text: "Команда",
        component: <AdminDoctors />,
    },
    {
        path: "/admin-panel/faq",
        image: <FaQuestionCircle />,
        text: "FAQ",
        component: <AdminFaq />,
    },
    {
        path: "/admin-panel/news",
        image: <FaNewspaper />,
        text: "Новини",
        component: <AdminNews />,
    },
    {
        path: "/admin-panel/rewards",
        image: <FaMedal />,
        text: "Нагороди",
        component: <AdminRewards />,
    },
    {
        path: "/admin-panel/services",
        image: <RiServiceFill />,
        text: "Послуги",
        component: <AdminServices />,
    },
    {
        path: "/admin-panel/services-categories",
        image: <FaBookMedical />,
        text: "Категорії послуг",
        component: <AdminServiceCategory />,
    },
    {
        path: "/admin-panel/specialties",
        image: <TbMedicalCrossFilled />,
        text: "Спеціальності",
        component: <AdminSpecialties />,
    },
    {
        path: "/admin-panel/vacancies",
        image: <IoPeopleCircle />,
        text: "Вакансії",
        component: <AdminVacancies />,
    },
    {
        path: "/admin-panel/partners",
        image: <FaPeopleGroup />,
        text: "Партнери",
        component: <AdminPartners />,
    },
    {
        path: "/admin-panel/documents",
        image: <IoDocumentText />,
        text: "Документи",
        component: <AdminDocuments />,
    },
];

export default adminNavigation;
