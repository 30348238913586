import React from "react";
import { FaHandHoldingMedical } from "react-icons/fa6";
import { Link } from "react-router-dom";

const ServiceCard = ({ service = null }) => {
    return (
        <Link
            to={`/services/${service._id}`}
            className="w-full max-w-[250px] shadow-lg min-h-[200px] md:min-h-max p-3 md:p-1 rounded-xl hover:scale-105 transition-all bg-curious-blue-50"
        >
            <div className="relative flex flex-col gap-4 h-full  items-center py-5 md:py-1 text-center">
                <FaHandHoldingMedical className="absolute bottom-3 left-3 text-8xl opacity-15 text-curious-blue-500 md:hidden" />
                <p className="font-semibold font-montserrat text-xl text-curious-blue-600">
                    {service?.categoryName}
                </p>
                <p className="font-medium font-montserrat text-base text-curious-blue-800 md:hidden">
                    {service?.descr}
                </p>
            </div>
        </Link>
    );
};

export default ServiceCard;
