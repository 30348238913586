import React, { useState } from "react";
import styles from "./Button.module.scss";
import ContactModal from "../ContactModal/ContactModal";

const Button = ({ text, action, modal, stylesVariants = "" }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <button
                className={styles.button + " " + stylesVariants}
                onClick={(e) => {
                    if (modal) {
                        setIsModalOpen((prev) => !prev);
                    } else {
                        return action || null;
                    }
                }}
            >
                {text}
            </button>
            {modal ? (
                <ContactModal active={isModalOpen} setActive={setIsModalOpen} />
            ) : (
                false
            )}
        </>
    );
};

export default Button;
