import React from "react";
import { useController } from "react-hook-form";

const Input = ({
    name,
    control,
    rules = {},
    disabled = false,
    defaultValue = "",
    ...props
}) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled,
    });

    return (
        <div className="flex flex-col gap-[2px] w-full">
            <input
                className="border-[1px] rounded-xl px-7 py-4 font-light font-montserrat text-lg text-curious-blue-600 outline-curious-blue-500 transition-all"
                {...field}
                {...props}
            />
            {error?.message && (
                <p className="font-light font-montserrat text-xs text-red-300">
                    {error?.message}
                </p>
            )}
        </div>
    );
};

export default Input;
