import FeedbackForm from "../../../components/FeedbackForm/FeedbackForm";
import FadeBox from "../../../components/Animation/FadeBox/FadeBox";

const Feedback = () => {
    return (
        <section>
            <FadeBox className="w-full max-w-[1200px] m-auto my-[100px] p-3">
                <div>
                    <FeedbackForm text="Запишіться онлайн, або зателефонуйте" />
                </div>
            </FadeBox>
        </section>
    );
};

export default Feedback;
