import React, { useEffect } from "react";
import styles from "./Main.module.scss";
import MainHead from "../../containers/MainPage/MainHead/MainHead";
import Statistic from "../../containers/MainPage/Statistic/Statistic";
import ServicesCategory from "../../containers/MainPage/ServicesCategory/ServicesCategory";
import Feedback from "../../containers/MainPage/Feedback/Feedback";
import Comments from "../../containers/MainPage/Comments/Comments";
import Doctors from "../../containers/MainPage/Doctors/Doctors";
import Faq from "../../containers/MainPage/Faq/Faq";
import News from "../../containers/MainPage/News/News";
import { scrollToTop } from "../../utils/helpers";
import Partners from "../../components/Partners/Partners";

const Main = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <main className={styles.main_wrapper}>
            <MainHead />
            <Statistic />
            <ServicesCategory />
            <Feedback />
            <Comments />
            <Doctors />
            <Faq />
            <News />
            <Partners />
        </main>
    );
};

export default Main;
