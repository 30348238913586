import React from "react";
import styles from "./MainHead.module.scss";
import people from "../../../image/doctors/without-bg.webp";
import { FaHeartPulse } from "react-icons/fa6";
import Button from "../../../components/Button/Button";
import BounceBox from "../../../components/Animation/BounceBox/BounceBox";
import AnimationText from "../../../components/Animation/AnimationText/AnimationText";

const MainHead = () => {
    return (
        <section className={styles.main_head_wrapper}>
            <div className={styles.main_head_content}>
                <div className={styles.main_head_text}>
                    <div className={styles.main_head_live_txt}>
                        <FaHeartPulse />
                        <p>Піклуйтесь про своє здоров'я</p>
                    </div>
                    <AnimationText
                        text="Медичний центр Діани Прокопової. Ми дбаємо про ваше
                        здоров'я"
                        className={styles.main_head_title}
                    />

                    <p className={styles.main_head_sec}>
                        Ми надаємо якісні медичні послуги у місті
                        Камʼянець-Подільський. У нашому колективі працюють
                        більше 25 досвідчених фахівців
                    </p>
                    <Button text={`Зв'язатись з нами`} modal={true} />
                </div>
                <div className={styles.main_head_img_block}>
                    <img
                        src={people}
                        className={styles.main_head_img}
                        alt="Diana Prokopova"
                    />
                    <BounceBox
                        delay={0.6}
                        className={styles.main_head_circle}
                    ></BounceBox>
                </div>
            </div>
        </section>
    );
};

export default MainHead;
