import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PagePagination = ({ count, setPage }) => {
    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <Stack spacing={2}>
            <Pagination
                count={+count}
                variant="outlined"
                color="primary"
                onChange={handleChange}
            />
        </Stack>
    );
};

export default PagePagination;
