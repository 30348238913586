import React from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminMenu from "../AdminMenu/AdminMenu";

const AdminLayout = () => {
    return (
        <>
            <AdminHeader />
            <AdminMenu />
            <Outlet />
        </>
    );
};

export default AdminLayout;
