import { Route, Routes } from "react-router-dom";
import Context from "./context/Context";
import Error from "./pages/Error/Error";
import Layout from "./components/Layout/Layout";
import Main from "./pages/Main/Main";
import mainRoutesData from "./data/mainRoutesData";
import adminRoutesData from "./data/adminRoutesData";
import Login from "./pages/Admin-pages/Login/Login";
import AdminLayout from "./components/Layout/AdminLayout";
import ProtectedRoute from "./protect-route/ProtectRoute";
import AdminPanel from "./pages/Admin-pages/AdminPanel/AdminPanel";

function App() {
    return (
        <Context.Provider value={{}}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Main />} />
                    {mainRoutesData?.map((route, index) => {
                        return (
                            <Route
                                path={route?.href}
                                element={route?.component}
                                key={index}
                            />
                        );
                    })}
                    <Route path="*" element={<Error />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/admin-panel" element={<AdminLayout />}>
                    <Route
                        index
                        element={<ProtectedRoute component={<AdminPanel />} />}
                    />
                    {adminRoutesData.map((route, index) => {
                        return (
                            <Route
                                path={route?.path}
                                element={
                                    <ProtectedRoute
                                        component={route?.component}
                                    />
                                }
                                key={index}
                            />
                        );
                    })}
                    <Route path="*" element={<Error />} />
                </Route>
            </Routes>
        </Context.Provider>
    );
}

export default App;
