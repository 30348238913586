import { useController } from "react-hook-form";
import { FaCheckCircle } from "react-icons/fa";

const CheckBox = ({
    name,
    control,
    rules = {},
    disabled = false,
    defaultValue = false,
    ...props
}) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled,
    });

    return (
        <div className="flex flex-col gap-[2px] w-full">
            <label
                htmlFor={props?.id}
                className="relative text-curious-blue-500 w-full flex items-center gap-4 h-[30px] cursor-pointer"
            >
                {field.value ? (
                    <span className=" absolute top-[3px] -left-[2px] text-2xl">
                        <FaCheckCircle />
                    </span>
                ) : (
                    <span className=" absolute top-[3px] -left-[2px] text-2xl w-[25px] h-[25px] border-curious-blue-500 border-2 rounded-full"></span>
                )}
                <p className=" ml-8 text-[20px] ">{props?.label}</p>
                <input
                    type="checkbox"
                    id="check"
                    hidden
                    {...field}
                    {...props}
                    checked={field.value}
                />
            </label>
        </div>
    );
};

export default CheckBox;
