import React from "react";
import { useController } from "react-hook-form";
import Select from "react-select";

const SelectMulti = ({
    name,
    control,
    rules = {},
    disabled = false,
    defaultValue = [],
    ...props
}) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled,
    });

    return (
        <>
            <Select
                className="border-[1px] rounded-xl cursor-pointer font-light font-montserrat text-lg text-curious-blue-500 outline-curious-blue-500 transition-all"
                {...field}
                {...props}
                options={props?.options}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        "&:hover": {
                            border: "1px solid #0fade6",
                        },
                        boxShadow: "none",
                        cursor: "pointer",
                    }),
                    option: (base, state) => ({
                        ...base,
                        cursor: "pointer",
                    }),
                }}
                isMulti
                menuPlacement="top"
            />
            {error?.message && (
                <p className="font-light font-montserrat text-xs text-red-300">
                    {error?.message}
                </p>
            )}
        </>
    );
};

export default SelectMulti;
