import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import { getPagination, scrollToTop } from "../../utils/helpers";
import { apiDevisions, apiDoctors } from "../../axios";
import PagePagination from "../../components/Pagination/PagePagination";
import DoctorCard from "../../components/DoctorCard/DoctorCard";
import Loader from "../../components/Loader/Loader";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import FadeBox from "../../components/Animation/FadeBox/FadeBox";
import FilterFields from "../../components/FilterFields/FilterFields";

const Doctors = () => {
    const { id } = useParams();
    const [doctors, setDoctors] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [personType, setPersonType] = useState("doctor");
    const [devisions, setDevisions] = useState([]);
    const [loader, setLoader] = useState(null);

    const getDevisions = useCallback(async (objParams) => {
        try {
            const result = await apiDevisions.getDevision(objParams);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getData = useCallback(
        async (objParams) => {
            try {
                const result = await apiDoctors.getDoctors(objParams);
                result?.length === 0 ? setLoader(true) : setLoader(false);
                const count =
                    objParams?.page === 1
                        ? await apiDoctors.getDoctorsCount(objParams)
                        : 0;
                setPagination((prev) =>
                    objParams?.page === 1 ? getPagination(count, limit) : prev
                );
                if (result.length === 0 && page > 1) {
                    setPage((prevPage) => prevPage - 1);
                    return;
                }
                setDoctors(result);
            } catch (error) {
                console.log(error);
            }
        },
        [limit, page]
    );

    useEffect(() => {
        scrollToTop();
        getData({
            limit: limit,
            page: page,
            search: search,
            specialty: search,
            isDoctor: personType,
            filters: {
                devision: id,
            },
        });
        getDevisions(id);
    }, [getData, getDevisions, limit, page, search, personType, id]);

    return (
        <section>
            <PageHead text={devisions?.nameDevision} />
            <div className="w-full max-w-[1200px] m-auto p-3">
                <FilterFields
                    placeholder="Пошук за ПІП або спеціальністю"
                    setLimit={setLimit}
                    setSearch={setSearch}
                    setPersonType={setPersonType}
                />
                {doctors.length !== 0 ? (
                    <div className="flex justify-center flex-col items-center">
                        <div className="flex justify-center items-center gap-5 flex-wrap">
                            {doctors?.map((doctor) => (
                                <DoctorCard doctor={doctor} key={doctor._id} />
                            ))}
                        </div>
                        <PagePagination
                            count={pagination.length}
                            setPage={setPage}
                        />
                    </div>
                ) : (
                    <div className="">
                        {!loader ? (
                            <Loader />
                        ) : (
                            <p className="text-center font-montserrat font-semibold my-5 text-xl text-curious-blue-500 uppercase">
                                Працівників не знайдено
                            </p>
                        )}
                    </div>
                )}
                <FadeBox className="my-7">
                    <FeedbackForm text="Запишіться онлайн, або зателефонуйте" />
                </FadeBox>
            </div>
        </section>
    );
};

export default Doctors;
