import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Divider } from "@mui/material";
import logo from "../../image/logo.png";
import adminNavigation from "../../data/adminRoutesData";
import { Link } from "react-router-dom";

const AdminBurgerMenu = () => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <>
            <button
                onClick={toggleDrawer(true)}
                className="flex-col gap-1 justify-center items-center hidden md:flex"
            >
                <span
                    className={`w-7 h-[4px] rounded-lg bg-curious-blue-600`}
                ></span>
                <span
                    className={`w-7 h-[4px] rounded-lg bg-curious-blue-600`}
                ></span>
                <span
                    className={`w-7 h-[4px] rounded-lg bg-curious-blue-600`}
                ></span>
            </button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <div className="w-full max-w-[500px] min-w-[300px] p-3">
                    <img
                        src={logo}
                        alt=""
                        className="w-full max-w-[150px] m-auto my-2"
                    />
                    <Divider />
                    <div className=" list-none my-5 flex flex-col gap-2">
                        {adminNavigation?.map((link, index) => {
                            return (
                                <Link
                                    to={link?.path}
                                    key={link?.text}
                                    onClick={toggleDrawer(false)}
                                    className="flex justify-between items-center font-montserrat font-medium text-curious-blue-600 text-lg relative"
                                >
                                    {link?.text}
                                    <div className="absolute right-0 opacity-65 text-3xl">
                                        {link?.image}
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default AdminBurgerMenu;
