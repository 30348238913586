import React from "react";
import { useController } from "react-hook-form";

const InputFile = ({
    name,
    control,
    rules = {},
    disabled = false,
    defaultValue = "",
    nameImage = "",
    ...props
}) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled,
    });

    return (
        <div className="flex flex-col gap-[2px] w-full">
            <label
                htmlFor="file"
                className=" cursor-pointer rounded-xl px-7 py-2 text-center font-light text-curious-blue-50 bg-curious-blue-500 font-montserrat outline-curious-blue-500 transition-all"
            >
                {nameImage?.length !== 0 ? nameImage : "Оберіть зображення"}
                <input
                    className="hidden"
                    type="file"
                    id="file"
                    {...field}
                    {...props}
                />
            </label>
            {error?.message && (
                <p className="font-light font-montserrat text-xs text-red-300">
                    {error?.message}
                </p>
            )}
        </div>
    );
};

export default InputFile;
