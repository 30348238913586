import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminHead from "../../../containers/Universal/AdminHead/AdminHead";
import { apiAnalyses, apiComments } from "../../../axios";
import Table from "../../../components/TabelData/TableData";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import FilterFields from "../../../components/FilterFields/FilterFields";
import {
    alertForConfirmRequest,
    getCurrentDate,
    getPagination,
} from "../../../utils/helpers";
import { useModal } from "../../../components/Modals";
import {
    adminCommentsPost,
    adminCommentsUpdate,
    adminCommentsDelete,
} from "../../../axios/admin/manageComments";

const AdminComments = () => {
    const dispatchModal = useModal();
    const [commetns, setComments] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");

    const getData = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiComments.getComments(paramsObj);
                const count =
                    paramsObj?.page === 1
                        ? await apiAnalyses.getAnalysesCount(paramsObj)
                        : 0;
                setPagination((prev) =>
                    paramsObj?.page === 1 ? getPagination(count, limit) : prev
                );
                if (result.length === 0 && page > 1) {
                    setPage((prevPage) => prevPage - 1);
                    return;
                }
                setComments(result);
            } catch (error) {
                console.log(error);
            }
        },
        [limit, page]
    );

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            const currentDate = getCurrentDate();
            await adminCommentsPost({ ...fieldsObj, date: currentDate });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminCommentsDelete({
                id: id,
            });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            const currentDate = getCurrentDate();
            await adminCommentsUpdate({ ...fieldsObj, date: currentDate });
            getData({
                page: page,
                limit: limit,
                search: search,
            });
        },
        [getData, search, page, limit]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal("createUpdateComments", {
                obj: obj,
                handleSuccess: (result) => {
                    obj?.id ? updateHandler(result) : createHandler(result);
                },
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            page: page,
            limit: limit,
            search: search,
        });
    }, [getData, page, search, limit]);

    const columns = useMemo(
        () => [
            {
                key: "author",
                children: "Автор",
            },
            {
                key: "descr",
                children: "Коментар",
            },
            {
                key: "date",
                children: "Дата",
            },
            {
                key: "settings",
                children: "Керування",
                style: {
                    width: 75,
                },
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            commetns.map((itm) => ({
                rowData: [
                    {
                        key: "author",
                        children: itm?.author,
                    },
                    {
                        key: "descr",
                        children: itm?.descr,
                    },
                    {
                        key: "date",
                        children: itm?.date,
                    },
                    {
                        key: "setting",
                        style: {
                            width: 75,
                        },
                        children: (
                            <div className="flex justify-center items-center gap-3">
                                <button
                                    className=" text-curious-blue-600 text-xl"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            author: itm?.author,
                                            date: itm?.date,
                                            descr: itm?.descr,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className=" text-red-400 text-3xl"
                                    onClick={() =>
                                        alertForConfirmRequest(
                                            deleteHandler,
                                            itm?._id
                                        )
                                    }
                                >
                                    <MdDelete />
                                </button>
                            </div>
                        ),
                    },
                ],
            })),
        [commetns, deleteHandler, createOrUpdate]
    );
    return (
        <section className=" pl-20 pr-5 md:pl-5">
            <div className="w-full max-w-[1200px] m-auto">
                <AdminHead title={"Коментарі"} modalAction={createOrUpdate} />

                <FilterFields
                    setSearch={setSearch}
                    setLimit={setLimit}
                    placeholder="Швидкий пошук"
                />
                {commetns?.length !== 0 ? (
                    <Table
                        rows={dataRows}
                        cols={columns}
                        pagination={{
                            count: pagination.length,
                            setPage: setPage,
                        }}
                    />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default AdminComments;
