import React, { useCallback, useEffect, useState } from "react";
import { apiDocuments } from "../../../axios";
import Loader from "../../../components/Loader/Loader";

const Documents = () => {
    const [documents, setDocuments] = useState([]);

    const getData = useCallback(async (objectParamas) => {
        try {
            const result = await apiDocuments.getDocuments(objectParamas);
            setDocuments(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({});
    }, [getData]);

    return (
        <section className=" py-[50px] relative bg-curious-blue-50 bg-opacity-40">
            <div className=" w-full max-w-[1200px] m-auto p-3">
                <h2 className=" font-semibold font-montserrat text-[44px] text-curious-blue-600 text-center">
                    Установчі документи
                </h2>
                {documents?.length !== 0 ? (
                    <ul className=" list-disc px-4 text-curious-blue-600 my-10 flex flex-col gap-1">
                        {documents?.map((document) => (
                            <li key={document?._id}>
                                <a
                                    href={document?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="font-montserrat font-medium text-lg text-curious-blue-600 border-b-2 border-transparent hover:border-curious-blue-600 transition-all"
                                >
                                    {document?.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default Documents;
