import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import PhotoAlbum from "react-photo-album";
import "yet-another-react-lightbox/styles.css";
import Loader from "../../components/Loader/Loader";
import "./Rewards.scss";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

const RewardGallery = ({ data }) => {
    const [index, setIndex] = useState(-1);
    const [slides, setSlides] = useState([]);

    function addImageSizeToObject(obj, imageUrl) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = function () {
                // Додаємо розміри зображення до об'єкта
                obj.width = img.width;
                obj.height = img.height;
                resolve(obj); // Повертаємо змінений об'єкт
            };

            img.onerror = function () {
                reject(new Error("Не вдалось завантажити зображення"));
            };

            img.src = imageUrl;
        });
    }
    useEffect(() => {
        data &&
            data.map((photo, index) => {
                addImageSizeToObject(photo, photo?.src)
                    .then((obj) => {
                        setSlides((prev) => [...prev, obj]);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
    }, [data]);

    return (
        <>
            {slides.length !== 0 ? (
                <div>
                    <PhotoAlbum
                        layout="masonry"
                        photos={slides}
                        targetRowHeight={150}
                        onClick={({ index: current }) => setIndex(current)}
                    />

                    <Lightbox
                        plugins={[Captions]}
                        index={index}
                        slides={slides}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                    />
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default RewardGallery;
