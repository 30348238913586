import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminHead from "../../../containers/Universal/AdminHead/AdminHead";
import { apiVacancies } from "../../../axios";
import Table from "../../../components/TabelData/TableData";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import FilterFields from "../../../components/FilterFields/FilterFields";
import { alertForConfirmRequest } from "../../../utils/helpers";
import { useModal } from "../../../components/Modals";
import {
    adminVacanciesPost,
    adminVacanciesUpdate,
    adminVacanciesDelete,
} from "../../../axios/admin/manageVacancies";

const AdminVacancies = () => {
    const dispatchModal = useModal();
    const [vacancies, setVacancies] = useState([]);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiVacancies.getVacancies(paramsObj);
            setVacancies(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            await adminVacanciesPost(fieldsObj);
            getData({
                search: search,
                limit: limit,
            });
        },
        [getData, search, limit]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminVacanciesDelete({
                id: id,
            });
            getData({
                search: search,
                limit: limit,
            });
        },
        [getData, search, limit]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            await adminVacanciesUpdate(fieldsObj);
            getData({
                search: search,
                limit: limit,
            });
        },
        [getData, search, limit]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal("createUpdateVacancies", {
                obj: obj,
                handleSuccess: (result) => {
                    obj?.id ? updateHandler(result) : createHandler(result);
                },
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            search: search,
            limit: limit,
        });
    }, [getData, search, limit]);

    const columns = useMemo(
        () => [
            {
                key: "vacancyName",
                children: "Назва вакансії",
            },
            {
                key: "descr",
                children: "Опис вакансії",
            },
            {
                key: "settings",
                children: "Керування",
                style: {
                    width: 75,
                },
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            vacancies?.map((itm) => ({
                rowData: [
                    {
                        key: "vacancyName",
                        children: itm?.vacancyName,
                    },
                    {
                        key: "descr",
                        children: itm?.descr,
                    },
                    {
                        key: "setting",
                        style: {
                            width: 75,
                        },
                        children: (
                            <div className="flex justify-center items-center gap-3">
                                <button
                                    className=" text-curious-blue-600 text-xl"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            ...itm,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className=" text-red-400 text-3xl"
                                    onClick={() =>
                                        alertForConfirmRequest(
                                            deleteHandler,
                                            itm?._id
                                        )
                                    }
                                >
                                    <MdDelete />
                                </button>
                            </div>
                        ),
                    },
                ],
            })),
        [vacancies, deleteHandler, createOrUpdate]
    );
    return (
        <section className=" pl-20 pr-5 md:pl-5">
            <div className="w-full max-w-[1200px] m-auto">
                <AdminHead title={"Вакансії"} modalAction={createOrUpdate} />

                <FilterFields
                    setSearch={setSearch}
                    placeholder="Швидкий пошук"
                    setLimit={setLimit}
                />
                {vacancies?.length !== 0 ? (
                    <Table rows={dataRows} cols={columns} />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default AdminVacancies;
