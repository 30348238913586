import React from "react";
import { useController, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

const PhoneInput = ({ name, control, defaultValue = "" }) => {
    const {
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    });

    return (
        <div className="flex flex-col gap-[2px] w-full">
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <InputMask
                        mask="+38(099)-999-99-99"
                        value={value}
                        placeholder="+38(0__)-___-__-__"
                        onChange={onChange}
                    >
                        {(inputProps) => (
                            <input
                                {...inputProps}
                                name={name}
                                type="tel"
                                className="w-full border-[1px] rounded-xl px-7 py-4 font-light font-montserrat text-lg text-curious-blue-600 outline-curious-blue-500 transition-all"
                            />
                        )}
                    </InputMask>
                )}
            />
            {error?.message && (
                <p className="font-light font-montserrat text-xs text-red-300">
                    {error?.message}
                </p>
            )}
        </div>
    );
};

export default PhoneInput;
