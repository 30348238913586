import { Link, useLocation, useNavigate } from "react-router-dom";

const Breadcrumbs = ({ nameCrumb }) => {
    const location = useLocation();
    const navigate = useNavigate();

    let changedCrumb = "";

    function changeNameCrumb(crumbElement) {
        switch (crumbElement) {
            case "/":
                changedCrumb = "Головна";
                break;
            case "services":
                changedCrumb = "Послуги";
                break;
            case "doctors":
                changedCrumb = "Лікарі філії";
                break;
            case "news":
                changedCrumb = "Новини";
                break;
            case "devisions":
                changedCrumb = "Філії";
                break;
            default:
                changedCrumb = nameCrumb;
                break;
        }
    }

    let currentLink = "";

    const locationCrumbs = location?.pathname.split("/");
    const crumb = ["/", ...locationCrumbs]
        .filter((crumb) => crumb !== "")
        .map((crumb) => {
            currentLink += `${crumb}`;
            changeNameCrumb(crumb);

            return (
                <div
                    className=" font-sans font-medium text-lg text-curious-blue-600 "
                    key={crumb}
                >
                    {currentLink === "/doctors" ? (
                        <p
                            className=" cursor-pointer"
                            onClick={() => navigate(-1)}
                        >
                            {changedCrumb}
                        </p>
                    ) : (
                        <Link to={currentLink}>{changedCrumb} |</Link>
                    )}
                </div>
            );
        });
    crumb.pop();

    return <div className="flex gap-3">{crumb}</div>;
};

export default Breadcrumbs;
