import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { MAPKEY } from "../../data/apiPath";
import mapStyle from "./MapStyles";

const DevisionMap = ({ location }) => {
    const libraries = ["places"];
    const mapContainerStyle = {
        width: "100vw",
        height: "70vh",
    };
    const center = {
        lat: +location.lt,
        lng: +location.ln,
    };
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: MAPKEY,
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={16}
                center={center}
                options={{ styles: mapStyle }}
            >
                <Marker position={center} />
            </GoogleMap>
        </div>
    );
};

export default DevisionMap;
