import React, { useCallback, useEffect, useState } from "react";
import styles from "./Comments.module.scss";
import { apiComments } from "../../../axios";
import people from "../../../image/people.svg";
import SliderComments from "../../../components/SliderComments/SliderComments";
import BlockCircles from "../../../components/BlockCircles/BlockCircles";
import Loader from "../../../components/Loader/Loader";

const Comments = () => {
    const [comments, setComments] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiComments.getComments(paramsObj);
            setComments(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <section className={styles.comments_wrapper}>
            <div className={styles.comments_content}>
                <div className={styles.comments_images}>
                    <img src={people} alt="People with comments" />
                </div>
                <div className={styles.comments_container}>
                    {comments.length !== 0 ? (
                        <SliderComments data={comments} size={10} />
                    ) : (
                        <Loader />
                    )}
                </div>
                <BlockCircles position="right" />
            </div>
        </section>
    );
};

export default Comments;
