import React, { useCallback, useEffect, useState } from "react";
import styles from "./Statistic.module.scss";
import { apiDevisions, apiDoctors, apiServices } from "../../../axios";
import Counter from "../../../components/Counter/Counter";
import FadeBox from "../../../components/Animation/FadeBox/FadeBox";

const Statistic = () => {
    const [devision, setDevision] = useState(null);
    const [services, setServices] = useState(null);
    const [doctors, setDoctors] = useState(null);

    const getDataDevision = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevision(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataDoctors = useCallback(async (paramsObj) => {
        try {
            const result = await apiDoctors.getDoctors(paramsObj);
            setDoctors(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataServices = useCallback(async (paramsObj) => {
        try {
            const result = await apiServices.getServices(paramsObj);
            setServices(result);
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => {
        getDataDevision("65e0470fea2ed03c9db26d10");
        getDataDoctors({
            isDoctor: "doctor",
            limit: 50,
        });
        getDataServices({
            limit: 400,
        });
    }, [getDataDevision, getDataServices, getDataDoctors]);

    return (
        <section className={styles.statistic_wrapper}>
            <FadeBox className={styles.statistic_content}>
                <div className={styles.statistic_box}>
                    <Counter value={doctors?.length} delay={200} />
                    <p className={styles.statistic_text}>Лікарів</p>
                </div>
                <div className={styles.statistic_box}>
                    <Counter
                        value={devision?.statistic?.clients}
                        delay={20}
                        fastCount
                    />
                    <p className={styles.statistic_text}>
                        Задоволених <br /> клієнтів
                    </p>
                </div>
                <div className={styles.statistic_box}>
                    <Counter value={services?.length} delay={30} />
                    <p className={styles.statistic_text}>
                        Медичних <br /> послуг
                    </p>
                </div>
                <div className={styles.statistic_box}>
                    <Counter value={devision?.statistic?.stage} delay={200} />
                    <p className={styles.statistic_text}>
                        Років <br /> досвіду
                    </p>
                </div>
            </FadeBox>
        </section>
    );
};

export default Statistic;
