import React, { useEffect } from "react";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import { scrollToTop } from "../../utils/helpers";
import AllServicesCategories from "../../containers/ServicesPage/AllServicesCategories/AllServicesCategories";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import Statistic from "../../containers/MainPage/Statistic/Statistic";
import FadeBox from "../../components/Animation/FadeBox/FadeBox";

const Services = () => {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <section className="">
            <PageHead text={"Послуги для Вас"} />
            <FadeBox>
                <Statistic />
            </FadeBox>
            <div className="w-full max-w-[1200px] m-auto">
                <h3 className=" my-10 font-montserrat font-semibold text-4xl text-center text-curious-blue-600">
                    Оберіть категорію
                </h3>
                <FadeBox>
                    <AllServicesCategories />
                </FadeBox>
                <FadeBox className=" my-28">
                    <FeedbackForm text="Запишіться онлайн, або зателефонуйте" />
                </FadeBox>
            </div>
        </section>
    );
};

export default Services;
