import * as yup from "yup";

const REQUIRED_MESSAGE = "Поле обовʼязкове для заповнення";
const MIN_MESSAGE = "Мінімум 3 символа";
const LINK_RULES =
    "Посилання на запис повинно починатись на http:// або https://";

const schema = yup.object().shape({
    firstName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    secondName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    surname: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE),
    specialtyCategory: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    devision: yup
        .array()
        .required(REQUIRED_MESSAGE)
        .min(1, "Оберіть хочаб 1 категорію"),
    education: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE),
    specialty: yup
        .array()
        .required(REQUIRED_MESSAGE)
        .min(1, "Оберіть хочаб 1 категорію"),
    // treatmentLink: yup
    //     .string()
    //     .trim()
    //     .required(REQUIRED_MESSAGE)
    //     .matches(/^(https?|ftp):\/\//, LINK_RULES),
});

export default schema;
