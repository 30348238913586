import React from "react";
import video from "../../../image/about_video.mp4";
import poster from "../../../image/video_poster.png";

const Video = () => {
    return (
        <section className="w-full">
            <div className="w-full max-w-[1200px] m-auto flex justify-center p-3 ">
                <video
                    src={video}
                    controls
                    className="rounded-xl shadow-mt w-full max-w-[800px] max-h-[460px] object-contain"
                    controlsList="nodownload"
                    poster={poster}
                    muted
                ></video>
            </div>
        </section>
    );
};

export default Video;
