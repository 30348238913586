import React from "react";
import styles from "./NewsCard.module.scss";
import { Link } from "react-router-dom";
import fakeNew from "../../image/fake_new.jpeg";

const NewsCard = ({ news }) => {
    return (
        <Link to={`/news/${news?._id}`}>
            <div className={styles.news_card_wrapper}>
                <div className={styles.news_card_img_box}>
                    <img
                        src={news?.image ? news?.image : fakeNew}
                        className={styles.news_card_img}
                        alt={news?.title}
                    />
                    <p className={styles.news_card_date}>{news?.date}</p>
                </div>
                <div className={styles.news_card_text}>
                    <p className={styles.news_card_title}>{news?.title}</p>
                    <div
                        className={styles.news_card_descr}
                        dangerouslySetInnerHTML={{
                            __html:
                                news?.description?.length >= 40
                                    ? news?.description?.slice(0, 40) + "..."
                                    : news?.description,
                        }}
                    />
                    <p className={styles.news_card_descr}></p>
                </div>
            </div>
        </Link>
    );
};

export default NewsCard;
