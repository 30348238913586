import React, { useEffect, useState, useCallback } from "react";
import styles from "./SliderDoctors.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import DoctorCard from "../DoctorCard/DoctorCard";
import { apiDoctors } from "../../axios";

const SliderDoctors = ({
    data = [],
    specialty = [],
    size = 100,
    docId,
    status,
}) => {
    const [filteredDoctors, setFilteredDoctors] = useState([]);

    const paginationStyles = {
        "--swiper-pagination-color": "#0fade6",
        "--swiper-pagination-bullet-inactive-color": "#e1f4fe",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "12px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
    };

    const getDataDoctors = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiDoctors.getDoctors(paramsObj);
                setFilteredDoctors(result.filter((doc) => doc._id !== docId));
            } catch (error) {
                console.log(error);
            }
        },
        [docId]
    );

    useEffect(() => {
        getDataDoctors({
            filters: {
                specialtiesName: {
                    $in: specialty,
                },
            },
        });
    }, [getDataDoctors]);

    return (
        <div className={styles.slider_doctors_wrapper}>
            {status && (
                <p className="text-center font-montserrat font-semibold text-xl my-10 text-curious-blue-700">
                    {`Працівники схожої спеціальності ${
                        filteredDoctors.length === 0 ? "відсутні" : ""
                    }`}
                </p>
            )}
            <Swiper
                spaceBetween={20}
                style={paginationStyles}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 2,
                    },
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper m-auto"
            >
                {specialty?.length !== 0
                    ? filteredDoctors.map((doctor) => {
                          return (
                              <SwiperSlide key={doctor?._id}>
                                  <DoctorCard doctor={doctor} />
                              </SwiperSlide>
                          );
                      })
                    : data?.slice(0, size).map((doctor) => (
                          <SwiperSlide key={doctor?._id}>
                              <DoctorCard doctor={doctor} />
                          </SwiperSlide>
                      ))}
            </Swiper>
        </div>
    );
};

export default SliderDoctors;
