import React, { useCallback, useEffect, useState } from "react";
import styles from "./ServicesCategory.module.scss";
import { apiServicesCategory } from "../../../axios";
import { FaHandHoldingMedical } from "react-icons/fa6";
import Loader from "../../../components/Loader/Loader";
import Link from "../../../components/Link/Link";
import BlockCircles from "../../../components/BlockCircles/BlockCircles";
import FadeBox from "../../../components/Animation/FadeBox/FadeBox";
import ServiceCard from "../../../components/ServiceCard/ServiceCard";

const ServicesCategory = () => {
    const [servCategory, setServCategory] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiServicesCategory.getServicesCategories(
                paramsObj
            );
            setServCategory(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <FadeBox className={styles.service_categories_wrapper}>
            <BlockCircles />
            <div className={styles.service_categories_content}>
                <div className={styles.service_categories_text}>
                    <h2 className={styles.service_categories_tit_txt}>
                        Наші послуги
                    </h2>
                    <p className={styles.service_categories_sec_txt}>
                        Для Вас великий спектр різноманітних послуг для кращого
                        рішення
                    </p>
                </div>
                {servCategory?.length !== 0 ? (
                    <div className={styles.service_categories_boxes}>
                        {servCategory?.slice(0, 4).map((itm) => (
                            <ServiceCard service={itm} key={itm?._id} />
                        ))}
                    </div>
                ) : (
                    <Loader />
                )}
                <div className={styles.service_categories_button}>
                    <Link
                        path={"services"}
                        text={"Всі послуги"}
                        whiteMode={true}
                    />
                </div>
            </div>
        </FadeBox>
    );
};

export default ServicesCategory;
