import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminHead from "../../../containers/Universal/AdminHead/AdminHead";
import { apiDoctors } from "../../../axios";
import Table from "../../../components/TabelData/TableData";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import FilterFields from "../../../components/FilterFields/FilterFields";
import { alertForConfirmRequest, getPagination } from "../../../utils/helpers";
import { useModal } from "../../../components/Modals";
import {
    adminDoctorsPost,
    adminDoctorsUpdate,
    adminDoctorsDelete,
} from "../../../axios/admin/manageDoctors";

const AdminDoctors = () => {
    const dispatchModal = useModal();
    const [doctors, setDoctors] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [personType, setPersonType] = useState("doctor");

    const getData = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiDoctors.getDoctors(paramsObj);
                const count =
                    page === 1
                        ? await apiDoctors.getDoctorsCount(paramsObj)
                        : 1;
                setPagination((prev) =>
                    page === 1 ? getPagination(count, limit) : prev
                );
                if (result.length === 0 && page > 1) {
                    setPage((prevPage) => prevPage - 1);
                    return;
                }
                setDoctors(result);
            } catch (error) {
                console.log(error);
            }
        },
        [limit, page]
    );

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            await adminDoctorsPost(fieldsObj);
            getData({
                page: page,
                limit: limit,
                search: search,
                isDoctor: personType,
            });
        },
        [getData, search, page, limit, personType]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminDoctorsDelete({
                id: id,
            });
            getData({
                page: page,
                limit: limit,
                search: search,
                isDoctor: personType,
            });
        },
        [getData, search, page, limit, personType]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            await adminDoctorsUpdate(fieldsObj);
            getData({
                page: page,
                limit: limit,
                search: search,
                isDoctor: personType,
            });
        },
        [getData, search, page, limit, personType]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal("createUpdateDoctors", {
                obj: obj,
                handleSuccess: (result) => {
                    obj?.id ? updateHandler(result) : createHandler(result);
                },
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            page: page,
            limit: limit,
            search: search,
            isDoctor: personType,
        });
    }, [getData, page, search, limit, personType]);

    const columns = useMemo(
        () => [
            {
                key: "pip",
                children: "ПІП",
            },
            {
                key: "devisionsName",
                children: "МНП",
            },
            {
                key: "specialtiesName",
                children: "Спеціальність",
            },
            {
                key: "education",
                children: "Освіта",
            },
            {
                key: "specialtyCategory",
                children: "Категорія",
            },
            {
                key: "treatmentLink",
                children: "Запис",
            },
            {
                key: "image",
                children: "Зображення",
            },
            {
                key: "isDoctor",
                children: "Лікар/Мед.персонал",
            },
            {
                key: "settings",
                children: "Керування",
                style: {
                    width: 75,
                },
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            doctors?.map((itm) => ({
                rowData: [
                    {
                        key: "pip",
                        children: `${itm?.firstName} ${itm?.secondName} ${itm?.surname}`,
                    },
                    {
                        key: "devisionsName",
                        children: itm?.devisionsName
                            ?.map((devision) => devision)
                            .join(", "),
                    },
                    {
                        key: "specialtiesName",
                        children: itm?.specialtiesName
                            ?.map((devision) => devision)
                            .join(", "),
                    },
                    {
                        key: "education",
                        children: itm?.education,
                    },
                    {
                        key: "specialtyCategory",
                        children: itm?.specialtyCategory,
                    },
                    {
                        key: "treatmentLink",
                        children: itm?.treatmentLink,
                    },
                    {
                        key: "image",
                        children: (
                            <img
                                src={itm?.image}
                                className=" w-full max-w-[35px] object-cover aspect-[3/4]"
                                alt="Фото"
                            />
                        ),
                    },
                    {
                        key: "isDoctor",
                        children:
                            itm?.isDoctor === "doctor"
                                ? "Лікар"
                                : itm?.isDoctor === "nurse"
                                ? "Медсестра"
                                : "Фахівець у сфері здоров'я",
                    },
                    {
                        key: "setting",
                        style: {
                            width: 75,
                        },
                        children: (
                            <div className="flex justify-center items-center gap-3">
                                <button
                                    className=" text-curious-blue-600 text-xl"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            firstName: itm?.firstName,
                                            secondName: itm?.secondName,
                                            surname: itm?.surname,
                                            specialtyCategory:
                                                itm?.specialtyCategory,
                                            education: itm?.education,
                                            treatmentLink: itm?.treatmentLink,
                                            isDoctor: itm?.isDoctor,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className=" text-red-400 text-3xl"
                                    onClick={() =>
                                        alertForConfirmRequest(
                                            deleteHandler,
                                            itm?._id
                                        )
                                    }
                                >
                                    <MdDelete />
                                </button>
                            </div>
                        ),
                    },
                ],
            })),
        [doctors, deleteHandler, createOrUpdate]
    );
    return (
        <section className=" pl-20 pr-5 md:pl-5">
            <div className="w-full max-w-[1200px] m-auto">
                <AdminHead
                    title={"Лікарі / Медсестри"}
                    modalAction={createOrUpdate}
                />

                <FilterFields
                    setSearch={setSearch}
                    setLimit={setLimit}
                    placeholder="Швидкий пошук"
                    setPersonType={setPersonType}
                />
                {doctors?.length !== 0 ? (
                    <Table
                        rows={dataRows}
                        cols={columns}
                        pagination={{
                            count: pagination.length,
                            setPage: setPage,
                        }}
                    />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default AdminDoctors;
