import React, { useCallback, useEffect, useState } from "react";
import styles from "./News.module.scss";
import { apiNews } from "../../../axios";
import Link from "../../../components/Link/Link";
import SliderNews from "../../../components/SliderNews/SliderNews";
import Loader from "../../../components/Loader/Loader";
import FadeBox from "../../../components/Animation/FadeBox/FadeBox";

const News = () => {
    const [news, setNews] = useState([]);

    const getData = useCallback(async (objectParams) => {
        try {
            const result = await apiNews.getNews(objectParams);
            setNews(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);
    return (
        <section className={styles.news_wrapper}>
            <FadeBox className={styles.news_content}>
                <h3 className={styles.news_title}>Останні новини</h3>
                {news?.length !== 0 ? (
                    <SliderNews data={news} size={5} />
                ) : (
                    <Loader />
                )}
            </FadeBox>
            <div className={styles.news_btn}>
                <Link path="news" text="Всі новини" />
            </div>
        </section>
    );
};

export default News;
