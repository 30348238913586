import React from "react";
import styles from "./SocialBtn.module.scss";
import { FaFacebook } from "react-icons/fa";
import { FaViber } from "react-icons/fa";
import { SiTelegram } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";

const SocialBtn = ({ devision }) => {
    return (
        <div className={styles.clinic_social}>
            {devision?.social?.facebook && (
                <a
                    href={devision?.social?.facebook}
                    className={styles.clinic_social_link}
                    alt="Facebook"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FaFacebook />
                </a>
            )}
            {devision?.social?.telegram && (
                <a
                    href={devision?.social?.telegram}
                    className={styles.clinic_social_link}
                    alt="Telegram"
                    target="_blank"
                    rel="noreferrer"
                >
                    <SiTelegram />
                </a>
            )}
            {devision?.social?.instagram && (
                <a
                    href={devision?.social?.instagram}
                    className={styles.clinic_social_link}
                    alt="Instagram"
                    target="_blank"
                    rel="noreferrer"
                >
                    <AiFillInstagram />
                </a>
            )}
            {devision?.social?.viber && (
                <a
                    href={devision?.social?.viber}
                    className={styles.clinic_social_link}
                    alt="Viber"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FaViber />
                </a>
            )}
        </div>
    );
};

export default SocialBtn;
