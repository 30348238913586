import React, { useCallback, useEffect, useState } from "react";
import { apiVacancies } from "../../../axios";
import VacancyCard from "../../../components/VacancyCard/VacancyCard";
import Loader from "../../../components/Loader/Loader";
import BlockCircles from "../../../components/BlockCircles/BlockCircles";

const Vacancies = () => {
    const [vacancies, setVacancies] = useState([]);
    const [emptyBase, setEmptyBase] = useState(false);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiVacancies.getVacancies(paramsObj);
            setVacancies(result);
            result?.length === 0 && setEmptyBase(true);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({});
    }, [getData]);

    return (
        <section>
            <div className="w-full max-w-[1200px] m-auto py-10 relative">
                <BlockCircles position="left" />
                <h1 className="font-montserrat font-semibold text-curious-blue-600 text-[44px] mb-10 text-center">
                    Вакансії
                </h1>
                <div className=" flex flex-wrap gap-3 justify-center items-center">
                    {vacancies.length !== 0 ? (
                        vacancies?.map((vacancy) => {
                            return (
                                <VacancyCard
                                    vacancy={vacancy}
                                    key={vacancy?._id}
                                />
                            );
                        })
                    ) : emptyBase ? (
                        <p className="text-2xl text-curious-blue-800 font-semibold text-center">
                            Нажаль вакансії відсутні
                        </p>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Vacancies;
