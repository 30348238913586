import React from "react";
import styles from "./SliderComments.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { BiSolidQuoteLeft } from "react-icons/bi";
import { BiSolidQuoteRight } from "react-icons/bi";

const SliderComments = ({ data, size = 100 }) => {
    const paginationStyles = {
        "--swiper-pagination-horizontal-gap": "20px",
        "--swiper-pagination-color": "#0fade6",
        "--swiper-pagination-bullet-inactive-color": "#e1f4fe",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "12px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
    };

    return (
        <div className={styles.slider_wrapper}>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                style={paginationStyles}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >
                {size &&
                    data
                        ?.toReversed()
                        .slice(0, size)
                        .map((elem) => (
                            <SwiperSlide key={elem?._id}>
                                <div className={styles.slide_box}>
                                    <div className={styles.slide_descr_box}>
                                        <div className=" self-start">
                                            <BiSolidQuoteLeft
                                                className={styles.first_qoute}
                                            />
                                        </div>
                                        <p className={styles.slide_descr}>
                                            {elem?.descr}
                                        </p>
                                        <div className=" self-end">
                                            <BiSolidQuoteRight
                                                className={styles.first_qoute}
                                            />
                                        </div>
                                    </div>
                                    <p className={styles.slide_author}>
                                        {elem?.author}
                                    </p>
                                    <p className={styles.slide_date}>
                                        {elem?.date}
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))}
            </Swiper>
        </div>
    );
};

export default SliderComments;
