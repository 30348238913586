import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import Modal from "@mui/material/Modal";
import { Input } from "../../../FormElement";
import { Divider } from "@mui/material";
import CheckBox from "../../../FormElement/components/CheckBox";
import SelectMulti from "../../../FormElement/components/SelectMulti";
import { apiServicesCategory } from "../../../../axios";

const CreateUpdateServices = ({
    visible,
    onCancel,
    obj = null,
    handleSuccess,
}) => {
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
    });

    const [categories, setCategories] = useState([]);

    const onSubmit = async (data) => {
        handleSuccess({
            ...data,
            category: data?.category?.map((itm) => itm?.value),
            namesCategories: data?.category?.map((itm) => itm?.label),
        });
        onCancel();
    };

    const getCategories = useCallback(async (paramsObj) => {
        try {
            const result = await apiServicesCategory.getServicesCategories(
                paramsObj
            );
            setCategories(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getCategories({
            limit: 50,
        });
    }, [getCategories]);

    useEffect(() => {
        reset(obj);
        return () => {
            reset({});
        };
    }, [reset, obj]);

    const optionsCategories = useMemo(
        () =>
            categories?.map((category) => ({
                label: category?.categoryName,
                value: category?._id,
            })),
        [categories]
    );

    return (
        <Modal
            open={visible}
            onClose={onCancel}
            className="flex justify-center items-center "
        >
            <div className=" bg-white p-5 rounded-lg">
                <p className=" font-montserrat text-curious-blue-500 text-xl text-center mb-3">
                    {obj?.id ? "Оновлення послуги" : "Додавання послуги"}
                </p>
                <Divider />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="p-3 w-[400px] flex flex-col gap-4"
                >
                    <Input
                        name={"serviceName"}
                        control={control}
                        placeholder="Назва послуги"
                    />
                    <Input
                        name={"price"}
                        control={control}
                        placeholder="Вартість"
                    />
                    <SelectMulti
                        name={"category"}
                        options={optionsCategories}
                        control={control}
                        placeholder={"Оберіть категорію"}
                    />
                    <CheckBox
                        name={"paidService"}
                        control={control}
                        id={"service"}
                        label={"Платна послуга"}
                    />

                    <div className=" flex justify-around items-center gap-3">
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-curious-blue-500 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                        >
                            {obj?.id ? "Оновити" : "Зберегти"}
                        </button>
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-red-400 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                            onClick={onCancel}
                        >
                            Відміна
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CreateUpdateServices;
