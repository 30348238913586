import AboutUs from "../pages/About-us/AboutUs";
import Contacts from "../pages/Contacts/Contacts";
import CurrentNew from "../pages/CurrentNew/CurrentNew";
import Devisions from "../pages/Devisions/Devisions";
import Doctor from "../pages/Doctor/Doctor";
import Doctors from "../pages/Doctors/Doctors";
import Faq from "../pages/Faq/Faq";
import News from "../pages/News/News";
import Rewards from "../pages/Rewards/Rewards";
import Service from "../pages/Service/Service";
import Services from "../pages/Services/Services";

const mainRoutes = [
    {
        href: "services",
        component: <Services />,
    },
    {
        href: "services/:id",
        component: <Service />,
    },
    {
        href: "devisions",
        component: <Devisions />,
    },
    {
        href: "devisions/:id",
        component: <Doctors />,
    },
    {
        href: "doctors/:id",
        component: <Doctor />,
    },
    {
        href: "about-us",
        component: <AboutUs />,
    },
    {
        href: "rewards",
        component: <Rewards />,
    },
    {
        href: "news",
        component: <News />,
    },
    {
        href: "news/:id",
        component: <CurrentNew />,
    },
    {
        href: "contacts",
        component: <Contacts />,
    },
    {
        href: "faq",
        component: <Faq />,
    },
];

export default mainRoutes;
