import AboutUs from "../pages/About-us/AboutUs";
import Contacts from "../pages/Contacts/Contacts";
import CurrentNew from "../pages/CurrentNew/CurrentNew";
import Doctor from "../pages/Doctor/Doctor";
import Doctors from "../pages/Doctors/Doctors";
import Faq from "../pages/Faq/Faq";
import News from "../pages/News/News";
import Rewards from "../pages/Rewards/Rewards";
import Service from "../pages/Service/Service";
import Services from "../pages/Services/Services";

const mainNavigation = [
    {
        linkName: "Головна",
        href: "/",
    },
    {
        linkName: "Про нас",
        href: "/about-us",
    },
    {
        linkName: "Послуги",
        href: "/services",
    },
    {
        linkName: "Наша команда",
        href: "/devisions",
    },
    {
        linkName: "Наші нагороди",
        href: "/rewards",
    },
    {
        linkName: "Новини",
        href: "/news",
    },
    {
        linkName: "Контакти",
        href: "/contacts",
    },
    {
        linkName: "Допомога",
        href: "/faq",
    },
];

export default mainNavigation;
