import React from "react";
import { personType } from "../../data/personsType";

const FilterFields = ({
    placeholder = "",
    setSearch,
    setLimit,
    setPersonType = "",
}) => {
    return (
        <div className="flex flex-wrap gap-3 items-center justify-center my-7">
            <p className=" font-montserrat font-medium text-curious-blue-600 text-lg">
                Швидкий пошук:
            </p>
            <input
                type="text"
                className="border-[1px] rounded-xl px-4 py-4 font-light font-montserrat text-lg text-curious-blue-600 outline-curious-blue-500 transition-all w-full max-w-[340px]"
                placeholder={placeholder}
                onChange={(e) => setSearch(e.target.value)}
            />
            <p className=" font-montserrat font-medium text-curious-blue-600 text-lg">
                Кількість:
            </p>
            <select
                onChange={(e) => setLimit(e.target.value)}
                className="font-montserrat font-medium cursor-pointer border rounded-lg p-4 text-curious-blue-800 text-lg outline-none"
            >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="500">Всі</option>
            </select>
            {setPersonType && (
                <>
                    <p className=" font-montserrat font-medium text-curious-blue-600 text-lg">
                        Тип:
                    </p>
                    <select
                        onChange={(e) => setPersonType(e.target.value)}
                        className="font-montserrat font-medium cursor-pointer border rounded-lg p-4 text-curious-blue-800 text-lg outline-none"
                    >
                        {personType?.map((elem) => (
                            <option key={elem?.value} value={elem?.value}>
                                {elem?.label}
                            </option>
                        ))}
                    </select>
                </>
            )}
        </div>
    );
};

export default FilterFields;
