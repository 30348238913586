import React, { useEffect } from "react";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import people from "../../image/doctors/prokopova.jpeg";
import FadeBox from "../../components/Animation/FadeBox/FadeBox";
import Statistic from "../../containers/MainPage/Statistic/Statistic";
import Comments from "../../containers/MainPage/Comments/Comments";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import { scrollToTop } from "../../utils/helpers";
import Administration from "../../components/Administration/Administration";
import Vacancies from "../../containers/About-Us/Vacancies/Vacancies";
import Video from "../../containers/About-Us/Video/Video";
import Documents from "../../containers/About-Us/Documents/Documents";

const AboutUs = () => {
    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <section>
            <PageHead text={"Про нас"} />
            <div className="w-full max-w-[1200px] m-auto p-3 flex justify-around md:flex-col gap-5 my-14">
                <FadeBox className="flex-1 md:max-w-[100%] max-w-[40%] flex flex-col gap-1 justify-center items-center">
                    <img
                        className=" rounded-xl"
                        src={people}
                        alt="Прокопова Діана Іванівна"
                    />
                    <p className=" font-montserrat font-semibold text-2xl text-curious-blue-600">
                        Прокопова Діана Іванівна
                    </p>
                    <p className=" font-montserrat font-medium text-base text-curious-blue-700">
                        Засновниця клініки, сімейний лікар
                    </p>
                    <p className=" font-montserrat font-medium text-base text-curious-blue-800">
                        D.PROKOPOVA
                    </p>
                </FadeBox>
                <FadeBox className="flex-1 md:text-center">
                    <p className=" font-montserrat font-medium text-base text-curious-blue-800">
                        Запрошуємо вас до медичного центру Діани Прокопової -
                        сучасного закладу, який пропонує високоякісні, ефективні
                        та доказові медичні послуги. Особливістю закладу є
                        надання допомоги повного амбулаторно-поліклінічного,
                        діагностичного циклу у зручний для вас час.
                        <br />
                        <br /> Під час відвідування пацієнти зможуть пройти
                        огляд, діагностичні та лабораторні обстеження,
                        маніпуляційні процедури в одному місці. Таким чином, ми
                        намагаємось забезпечити найвищий рівень медичної
                        доступності та комфорту,використовуючи сучасне
                        обладнання європейської якості.
                        <br />
                        <br /> Ми пропонуємо широкий спектр послуг в галузі
                        загальної медицини, діагностики, профілактики та
                        лікування хвороб різної складності за наступними
                        напрямками: сімейна медицина, терапія, педіатрія,
                        неврологія, дерматологія, кардіологія, ендокринологія,
                        ревматологія, акушерство-гінекологія, підтримка грудного
                        вигодовування,ЛОР, функціональна діагностика, УЗД
                        діагностика, сестринська справа, вакцинація, психологія,
                        косметологія, медична реабілітація, паліативна допомога.
                        <br />
                        <br />
                        Висококваліфікований медичний персонал постійно
                        проходить навчання для впровадження новітніх методів
                        діагностики, лікування та профілактики захворювань.
                        <br />
                        <br /> Ласкаво просимо до медичного центру Діани
                        Прокопової! Наше кредо-доступність, якість, доказовість!
                    </p>
                </FadeBox>
            </div>
            <Video />
            <Statistic />
            <Comments />
            <div className=" my-14">
                <FeedbackForm text="Запишіться онлайн, або зателефонуйте" />
            </div>
            <div className="my-14 ">
                <Vacancies />
            </div>
            <Administration />
            <Documents />
        </section>
    );
};

export default AboutUs;
