import React, { useCallback, useEffect, useState } from "react";
import { apiDoctors } from "../../../axios";
import styles from "./Doctors.module.scss";
import SliderDoctors from "../../../components/SliderDoctors/SliderDoctors";
import Loader from "../../../components/Loader/Loader";
import Link from "../../../components/Link/Link";
import FadeBox from "../../../components/Animation/FadeBox/FadeBox";

const Doctors = () => {
    const [doctors, setDoctors] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDoctors.getDoctors(paramsObj);
            setDoctors(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({
            isDoctor: "doctor",
        });
    }, [getData]);
    return (
        <section className={styles.doctors_wrapper}>
            <FadeBox className={styles.doctors_content}>
                <h3 className={styles.doctors_content_title}>Наша команда</h3>
                <div className={styles.doctors_content_slider}>
                    {doctors?.length !== 0 ? (
                        <SliderDoctors data={doctors} size={7} />
                    ) : (
                        <Loader />
                    )}
                </div>
                <div className={styles.doctors_btn}>
                    <Link text={"Всі лікарі"} path="devisions" />
                </div>
            </FadeBox>
        </section>
    );
};

export default Doctors;
