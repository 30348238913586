import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import { Input, Select } from "../../../FormElement";
import { Divider } from "@mui/material";
import SelectMulti from "../../../FormElement/components/SelectMulti";
import { apiSpecialties } from "../../../../axios";
import { apiDevisions } from "../../../../axios";
import { uploadImage } from "../../../FormElement/Utils";
import InputFile from "../../../FormElement/components/InputFile";
import Dialog from "@mui/material/Dialog";
import { TiDocumentDelete } from "react-icons/ti";
import { personType } from "../../../../data/personsType";

const CreateUpdateDoctors = ({
    visible,
    onCancel,
    obj = null,
    handleSuccess,
}) => {
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
    });

    const [specialties, setSpecialties] = useState([]);
    const [devisions, setDevisions] = useState([]);
    const [image, setImage] = useState("");

    const onSubmit = async (data) => {
        const resultNewUrl = await uploadImage(image);
        handleSuccess({
            ...data,
            devision: data?.devision?.map((itm) => itm?.value),
            devisionsName: data?.devision?.map((itm) => itm?.label),
            specialty: data?.specialty?.map((itm) => itm?.value),
            specialtiesName: data?.specialty?.map((itm) => itm?.label),
            image: resultNewUrl?.data?.display_url,
        });
        onCancel();
    };

    const getSpecialties = useCallback(async (paramsObj) => {
        try {
            const result = await apiSpecialties.getSlecialties(paramsObj);
            setSpecialties(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDevisions = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getSpecialties({
            limit: 100,
        });
        getDevisions({});
    }, [getDevisions, getSpecialties]);

    useEffect(() => {
        reset(obj);
        return () => {
            reset({});
        };
    }, [reset, obj]);

    const optionsSpecialties = useMemo(
        () =>
            specialties?.map((specialty) => ({
                label: specialty?.specialty,
                value: specialty?._id,
            })),
        [specialties]
    );
    const optionsDevisions = useMemo(
        () =>
            devisions?.map((devisions) => ({
                label: devisions?.nameDevision,
                value: devisions?._id,
            })),
        [devisions]
    );

    return (
        <Dialog
            open={visible}
            onClose={onCancel}
            className="flex justify-center items-center "
        >
            <div className=" bg-white p-5 rounded-lg">
                <p className=" font-montserrat text-curious-blue-500 text-xl text-center mb-3">
                    {obj?.id ? "Оновлення працівника" : "Додавання працівника"}
                </p>
                <Divider />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="p-3 w-[400px] flex flex-col gap-4"
                >
                    <Input
                        name={"firstName"}
                        control={control}
                        placeholder="Прізвище"
                    />
                    <Input
                        name={"secondName"}
                        control={control}
                        placeholder="Імʼя"
                    />
                    <Input
                        name={"surname"}
                        control={control}
                        placeholder="Побатькові"
                    />
                    <Input
                        name={"specialtyCategory"}
                        control={control}
                        placeholder="Категорія"
                    />
                    <SelectMulti
                        name={"devision"}
                        options={optionsDevisions}
                        control={control}
                        placeholder={"Оберіть МНП"}
                    />
                    <Input
                        name={"education"}
                        control={control}
                        placeholder="Освіта"
                    />
                    <SelectMulti
                        name={"specialty"}
                        options={optionsSpecialties}
                        control={control}
                        placeholder={"Оберіть спеціальність"}
                    />
                    <Input
                        name={"treatmentLink"}
                        control={control}
                        placeholder="Запис"
                    />
                    <div className="flex justify-around items-center gap-2">
                        <InputFile
                            name={"image"}
                            nameImage={image?.name}
                            control={control}
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                        {image?.length !== 0 ? (
                            <div className="">
                                <button
                                    className="border-[1px] rounded-xl px-7 py-[11px] font-light font-montserrat text-lg text-curious-blue-50 bg-red-400 outline-curious-blue-500 transition-all"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setImage("");
                                    }}
                                >
                                    <TiDocumentDelete />
                                </button>
                            </div>
                        ) : (
                            false
                        )}
                    </div>
                    <Select
                        name={"isDoctor"}
                        control={control}
                        data={personType}
                        placeholder={"Категорія працівника"}
                    />

                    <div className=" flex justify-around items-center gap-3">
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-curious-blue-500 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                        >
                            {obj?.id ? "Оновити" : "Зберегти"}
                        </button>
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-red-400 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            onClick={onCancel}
                        >
                            Відміна
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default CreateUpdateDoctors;
