import React, { useCallback, useEffect, useRef, useState } from "react";
import PageHead from "../../containers/Universal/PageHead/PageHead";
import { useParams, useNavigate } from "react-router-dom";
import { apiNews } from "../../axios";
import { scrollToTop } from "../../utils/helpers";
import SliderNews from "../../components/SliderNews/SliderNews";
import Loader from "../../components/Loader/Loader";
import fakeNew from "../../image/fake_new.jpeg";
import "draft-js/dist/Draft.css";
import "./RedactorStyles.scss";

const CurrentNew = () => {
    const { id } = useParams();
    const [currentNew, setCurrentNew] = useState(null);
    const [errorRequest, setErrorRequest] = useState(null);
    const [news, setNews] = useState([]);
    const [filteredNews, setFilteredNews] = useState([]);

    const navigate = useNavigate();
    const descriptionRef = useRef();

    const getCurrentNew = useCallback(async (paramsObj) => {
        try {
            const result = await apiNews.getNew(paramsObj);
            setCurrentNew(result);
        } catch (error) {
            console.log(error);
            error && setErrorRequest(error);
        }
    }, []);

    const getNews = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiNews.getNews(paramsObj);
                setNews(result);
                setFilteredNews(result.filter((news) => news?._id !== id));
            } catch (error) {
                console.log(error);
            }
        },
        [id]
    );

    useEffect(() => {
        currentNew &&
            descriptionRef?.current
                .querySelectorAll("div > p > a")
                .forEach((element) => {
                    element.setAttribute("target", "_blank");
                    console.log(element);
                });
        scrollToTop();
        getNews();
        getCurrentNew(id);
        errorRequest && navigate("/error");
    }, [getCurrentNew, getNews, id, errorRequest, navigate]);

    return (
        <section className="flex flex-col">
            {currentNew ? (
                <>
                    <PageHead text={currentNew?.title} />
                    <div className="w-full max-w-[1200px] m-auto p-10  min-h-[80vh]">
                        <div className=" flex flex-col gap-3 float-left ">
                            <p className="font-medium font-montserrat text-lg text-curious-blue-600">
                                {currentNew?.date}
                            </p>
                            <img
                                src={
                                    currentNew?.image
                                        ? currentNew?.image
                                        : fakeNew
                                }
                                alt={currentNew?.title}
                                className="w-full max-w-[600px] min-w-[400px] py-2 pr-4 md:min-w-[325px]"
                            />
                        </div>
                        <div
                            ref={descriptionRef}
                            className="mt-10 ck-content"
                            dangerouslySetInnerHTML={{
                                __html: currentNew?.description,
                            }}
                        />
                    </div>
                </>
            ) : (
                <div className="my-[150px]">
                    <Loader />
                </div>
            )}
            <div className="py-12 px-5">
                <p className=" text-center font-montserrat font-semibold text-[44px] text-curious-blue-600 mb-10">
                    Останні новини
                </p>
                {news.length !== 0 ? (
                    <SliderNews data={filteredNews} size={5} />
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
};

export default CurrentNew;
