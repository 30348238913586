import React from "react";
import { useController } from "react-hook-form";
import InputMask from "react-input-mask";

const TimeInput = ({
    name,
    control,
    rules = {},
    disabled = false,
    defaultValue = "",
    ...props
}) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled,
    });

    return (
        <>
            <InputMask
                mask="99:99-99:99"
                className="border-[1px] max-w-[137px] rounded-md px-4 py-1 font-light font-montserrat text-lg text-curious-blue-600 outline-curious-blue-500 transition-all"
                {...field}
                {...props}
            />
            {error?.message && (
                <p className="font-light font-montserrat text-xs text-red-300">
                    {error?.message}
                </p>
            )}
        </>
    );
};

export default TimeInput;
