import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import Dialog from "@mui/material/Dialog";
import {
    CoordinatesInput,
    Input,
    PhoneInput,
    TimeInput,
} from "../../../FormElement";
import { Divider } from "@mui/material";

const CreateUpdateDevision = ({
    visible,
    onCancel,
    obj = null,
    handleSuccess,
}) => {
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
        shouldFocusError: false,
    });

    const onSubmit = async (data) => {
        handleSuccess(data);
        onCancel();
    };
    useEffect(() => {
        reset(obj);
        return () => {
            reset({});
        };
    }, [reset, obj]);

    return (
        <Dialog
            open={visible}
            onClose={onCancel}
            className="flex justify-center items-center overflow-y-auto"
        >
            <div className=" bg-white p-5 rounded-lg">
                <p className=" font-montserrat text-curious-blue-500 text-xl text-center mb-3">
                    {obj?.id ? "Оновлення МНП" : "Додавання МНП"}
                </p>
                <Divider />
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="p-3 w-[450px] flex flex-col gap-4"
                >
                    <Input
                        name={"nameDevision"}
                        control={control}
                        placeholder="Назва МНП"
                    />
                    <div className="flex gap-2 justify-center text-center">
                        <label
                            htmlFor="lt"
                            className="font-light font-montserrat text-lg text-curious-blue-600"
                        >
                            Довгота {"\u00A0"}
                            {"\u00A0"}
                            <CoordinatesInput
                                name={"location.lt"}
                                id="lt"
                                control={control}
                            />
                        </label>
                        <label
                            htmlFor="ln"
                            className=" font-light font-montserrat text-lg text-curious-blue-600"
                        >
                            Широта {"\u00A0"}
                            {"\u00A0"}
                            <CoordinatesInput
                                name={"location.ln"}
                                control={control}
                                id="ln"
                            />
                        </label>
                    </div>
                    <Input
                        name={"location.adress"}
                        control={control}
                        placeholder="Адреса"
                    />
                    <div className=" flex gap-2 justify-around">
                        <label
                            htmlFor="weekdays"
                            className="text-center font-light font-montserrat text-lg text-curious-blue-600"
                        >
                            НП - ПТ
                            <br />
                            <TimeInput
                                name={"schedule.weekdays"}
                                id="weekdays"
                                control={control}
                            />
                        </label>
                        <label
                            htmlFor="saturday"
                            className="text-center font-light font-montserrat text-lg text-curious-blue-600"
                        >
                            СБ <br />
                            <TimeInput
                                name={"schedule.saturday"}
                                id="saturday"
                                control={control}
                            />
                        </label>
                        <label
                            htmlFor="sunday"
                            className="text-center font-light font-montserrat text-lg text-curious-blue-600"
                        >
                            НД <br />
                            <TimeInput
                                name={"schedule.sunday"}
                                id="sunday"
                                control={control}
                            />
                        </label>
                    </div>
                    <PhoneInput name={"phone.mobile"} control={control} />
                    <Input
                        name={"email"}
                        control={control}
                        placeholder="Email"
                    />
                    <div className=" flex flex-col gap-2">
                        <Input
                            name={"social.facebook"}
                            control={control}
                            placeholder="Facebook"
                        />
                        <Input
                            name={"social.viber"}
                            control={control}
                            placeholder="Viber"
                        />
                        <Input
                            name={"social.instagram"}
                            control={control}
                            placeholder="Instagram"
                        />
                        <Input
                            name={"social.telegram"}
                            control={control}
                            placeholder="Telegram"
                        />
                    </div>
                    <div className="flex gap-2 flex-col">
                        <Input
                            name={"statistic.clients"}
                            control={control}
                            placeholder="Клієнтів"
                        />
                        <Input
                            name={"statistic.stage"}
                            control={control}
                            placeholder="Досвід"
                        />
                    </div>
                    <div className=" flex justify-around items-center gap-3">
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-curious-blue-500 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                        >
                            {obj?.id ? "Оновити" : "Зберегти"}
                        </button>
                        <button
                            className=" w-full p-2 font-montserrat text-center text-curious-blue-50 bg-red-400 bg-opacity-70 rounded-xl hover:bg-opacity-100 transition-all"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                onCancel();
                            }}
                        >
                            Відміна
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default CreateUpdateDevision;
