import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import PagePagination from "../Pagination/PagePagination";

const TableData = ({ rows = [], cols = [], pagination = null }) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#0395d3",
            color: "#f0faff",
            font: "600 15px Montserrat",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            font: "400 16px Montserrat",
            padding: "10px 20px",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="customized table" sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {cols?.map((column, index) => {
                                return (
                                    <StyledTableCell key={index}>
                                        {column?.children}
                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => {
                            return (
                                <StyledTableRow
                                    key={index}
                                    className={row?.style}
                                >
                                    {row?.rowData?.map((itm) => {
                                        return (
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                                key={itm?.key}
                                                style={itm?.style}
                                            >
                                                {itm?.children}
                                            </StyledTableCell>
                                        );
                                    })}
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination && (
                <div className="my-5 w-full flex justify-center">
                    <PagePagination
                        count={pagination?.count}
                        setPage={pagination?.setPage}
                    />
                </div>
            )}
        </>
    );
};

export default TableData;
