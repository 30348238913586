import axios from "axios";
import { PATH } from "../../data/apiPath";
import { alertForRequest, getCookie } from "../../utils/helpers";

const adminFaqPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(`${PATH}/api/faq`, fieldsObj, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Запитання додано");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminFaqUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(`${PATH}/api/faq/${fieldsObj?.id}`, fieldsObj, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Запитання оновлено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminFaqDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/faq/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Запитання видалено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

export { adminFaqPost, adminFaqUpdate, adminFaqDelete };
