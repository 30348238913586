import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteCookie } from "../../utils/helpers";
import AdminBurgerMenu from "../AdminBurgerMenu/AdminBurgerMenu";

const AdminHeader = () => {
    const navigate = useNavigate();

    const logOut = () => {
        deleteCookie("tk");
        navigate("/login");
    };

    return (
        <header className="w-full p-3 bg-curious-blue-100  flex z-[1] justify-end md:justify-between fixed top-0 gap-3">
            <AdminBurgerMenu />
            <div className="flex gap-3">
                <Link
                    className="rounded-lg p-2 text-base text-curious-blue-50 font-montserrat bg-curious-blue-500 hover:text-curious-blue-50 hover:bg-curious-blue-700 transition-all"
                    to={"/"}
                    target="_blank"
                >
                    Повернутись до сайту
                </Link>
                <Link
                    className="rounded-lg p-2 text-base text-curious-blue-50 font-montserrat bg-curious-blue-500 hover:text-curious-blue-50 hover:bg-curious-blue-700 transition-all"
                    to={"/admin-panel"}
                >
                    Головна
                </Link>
                <button
                    onClick={logOut}
                    className=" rounded-lg p-2 text-base text-curious-blue-50 font-montserrat bg-curious-blue-500 hover:text-curious-blue-50 hover:bg-curious-blue-700 transition-all"
                >
                    Вихід
                </button>
            </div>
        </header>
    );
};

export default AdminHeader;
