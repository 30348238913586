import axios from "axios";
import { PATH } from "../../data/apiPath";
import { alertForRequest, getCookie } from "../../utils/helpers";

const adminVacanciesPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(`${PATH}/api/vacancies`, fieldsObj, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Вакансію додано");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminVacanciesUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(`${PATH}/api/vacancies/${fieldsObj?.id}`, fieldsObj, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Вакансію оновлено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

const adminVacanciesDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/vacancies/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie("tk"),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest("Вакансію видалено");
                    resolve(res.data);
                }
            })
            .catch((err) => {
                alertForRequest(err.response.data.message, err.code);
            });
    });
};

export { adminVacanciesPost, adminVacanciesUpdate, adminVacanciesDelete };
